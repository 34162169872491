import { useState, useEffect, ChangeEvent } from 'react'
import styles from './InputField.module.css'

// Component used on ModalGuardian

interface InputFieldProps {
    name: string
    placeholder?: string
    value: string
    maxLength?: number
    label: string
    dynamicWidth?: string
    dynamicHeight?: string
    backgroundColor?: string
    valid?: boolean
    onInputChange: (name: string, value: string) => void
}

const InputField: React.FC<InputFieldProps> = ({
    name,
    placeholder,
    value,
    maxLength,
    label,
    dynamicWidth,
    dynamicHeight,
    backgroundColor,
    valid,
    onInputChange,
}) => {
    const [inputValue, setInputValue] = useState<string>(value || '')
    const [isFocused, setIsFocused] = useState<boolean>(false)

    // Update the input field value when the "value" prop changes
    useEffect(() => {
        setInputValue(value || '')
    }, [value])

    // Update the "isFocused" state when the input is focused or blurred
    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = () => {
        setIsFocused(false)
    }

    // Handle input changes, and send name and value to the parent component
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value
        onInputChange(name, newValue)
    }

    return (
        <div className={styles['input-field-container']}>
            <p className={styles['input-label']}>{label}</p>
            <input
                type="text"
                name={name}
                maxLength={maxLength}
                placeholder={placeholder}
                value={inputValue}
                style={{
                    border: isFocused ? '2px solid var(--dark-color)' : '',
                    width: dynamicWidth,
                    backgroundColor: backgroundColor,
                    height: dynamicHeight,
                }}
                onChange={handleInputChange}
                className={
                    !valid
                        ? styles['input-field-invalid']
                        : styles['input-field']
                }
                onFocus={handleFocus}
                onBlur={handleBlur}
                autoComplete="on"
            />
        </div>
    )
}

export default InputField
