import { AnimatePresence, motion } from 'framer-motion'
import styles from './CardAboutSanctu.module.css'
import React, { useEffect, useState } from 'react'

// Component used on BannerAboutSanctu

interface CardAboutSanctuProps {
    title: string
    description: string
    img: string
    backgroundColor: string
    animationStart?: boolean
    index?: number
    onAnimationEnd?: (index: number) => void
}

const CardAboutSanctu: React.FC<CardAboutSanctuProps> = ({
    title,
    description,
    img,
    backgroundColor,
    animationStart,
    index,
    onAnimationEnd,
}) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const descriptionWithBreaks = {
        __html: description.replace(/\n/g, '<br />'),
    }

    const handleAnimationComplete = () => {
        if (onAnimationEnd) {
            onAnimationEnd(index ? index : 4)
        }
    }

    const isAnimationActive = animationStart && screenWidth > 450

    return (
        <div className={styles['aboutsanctu-card-container']}>
            <div className={styles['aboutsanctu-card-header']}>
                <AnimatePresence initial={isAnimationActive}>
                    <div
                        className={styles['aboutsanctu-card-image']}
                        style={{ backgroundColor: backgroundColor }}
                    >
                        <motion.img
                            src={img}
                            alt="Card About Sanctu img"
                            initial={{ scale: 1 }}
                            animate={
                                isAnimationActive
                                    ? { scale: 1.4 }
                                    : { scale: 1 }
                            }
                            transition={{ duration: 1 }}
                            exit={{ scale: 1 }}
                            onAnimationComplete={handleAnimationComplete}
                        />
                    </div>
                    <div
                        className={styles['aboutsanctu-card-header-text']}
                        key={index}
                    >
                        <h2>{title}</h2>
                    </div>
                </AnimatePresence>
            </div>
            <div className={styles['aboutsanctu-card-content']}>
                <p dangerouslySetInnerHTML={descriptionWithBreaks}></p>
            </div>
        </div>
    )
}

export default CardAboutSanctu
