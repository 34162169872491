import { useTranslation } from 'react-i18next'
import styles from './Banner500.module.css'

const Banner500 = () => {
    const { i18n } = useTranslation()
    return (
        <div className={styles['error-500-container']}>
            <h2>{i18n.t('Banner500.Title')}</h2>
            <p>{i18n.t('Banner500.Content')}</p>
        </div>
    )
}

export default Banner500
