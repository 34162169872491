import styles from './BannerAboutSanctu.module.css'
import Badge from '../Badge/Badge'
import { i18n } from '../../lib/i18n/i18n'
import ButtonNoBackground from '../ButtonNoBackground/ButtonNoBackground'
import CardAboutSanctu from './CardAboutSanctu'
import Card1Pix from '../../assets/images/CardAboutSanctuPic1.svg'
import Card2Pix from '../../assets/images/CardAboutSanctuPic2.svg'
import Card3Pix from '../../assets/images/CardAboutSanctuPic3.svg'
import { useEffect, useRef, useState } from 'react'
import { useTrail, animated } from 'react-spring'
import useWindowWidth from '../../hooks/useWindowWidth'

const BannerAboutSanctu = () => {
    const [currentCard, setCurrentCard] = useState(1)
    const timerRef = useRef<NodeJS.Timer | number | null>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const [isDesktop, setIsDesktop] = useState(false)
    const [animationStart, setAnimationStart] = useState(false)
    const ref = useRef(null)
    const windowWidth = useWindowWidth()

    // Check if viewport is desktop
    useEffect(() => {
        const checkIfDesktop = () => setIsDesktop(window.innerWidth > 450)
        checkIfDesktop()
        window.addEventListener('resize', checkIfDesktop)

        return () => {
            window.removeEventListener('resize', checkIfDesktop)
        }
    }, [])

    // Animation for icon when on desktop viewport - depends on isDesktop
    useEffect(() => {
        const animateNextCard = () => {
            setCurrentCard((prevCard) => {
                const nextCard = prevCard >= 3 ? 1 : prevCard + 1
                return nextCard
            })
        }

        if (isDesktop) {
            timerRef.current = setInterval(animateNextCard, 1200)
        }

        return () => {
            if (timerRef.current !== null) {
                clearInterval(timerRef.current as NodeJS.Timer)
            }
        }
    }, [isDesktop])

    const trailSprings = useTrail(3, {
        from: { transform: 'translateY(180px)' },
        to: { transform: 'translateY(0px)' },
        delay: 200,
        config: { duration: 400 },
        pause: !animationStart,
    })

    const cardData = [
        {
            title: i18n.t('BannerAboutSanctu.Card1'),
            description: i18n.t('BannerAboutSanctu.Card1Content'),
            img: Card1Pix,
            backgroundColor: 'var(--salmon-color)',
            animationStart: currentCard === 1,
            index: 1,
        },
        {
            title: i18n.t('BannerAboutSanctu.Card2'),
            description: i18n.t('BannerAboutSanctu.Card2Content'),
            img: Card2Pix,
            backgroundColor: 'var(--blue-color)',
            animationStart: currentCard === 2,
            index: 2,
        },
        {
            title: i18n.t('BannerAboutSanctu.Card3'),
            description: i18n.t('BannerAboutSanctu.Card3Content'),
            img: Card3Pix,
            backgroundColor: 'var(--yellow-color)',
            animationStart: currentCard === 3,
            index: 3,
        },
    ]

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.6,
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setAnimationStart(true)
                    observer.disconnect()
                }
            })
        }, options)

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            observer.disconnect()
        }
    })

    return (
        <div
            ref={containerRef}
            className={styles['banner-aboutsanctu-container']}
        >
            <div className={styles['banner-aboutsanctu-content']}>
                <div className={styles['banner-aboutsanctu-content-right']}>
                    <div>
                        <Badge text={i18n.t('BannerAboutSanctu.Badge')} />
                    </div>
                    <h2>{i18n.t('BannerAboutSanctu.Title')}</h2>
                </div>
                <div className={styles['banner-aboutsanctu-button']}>
                    <ButtonNoBackground
                        text={i18n.t('BannerAboutSanctu.Button')}
                        to={'/contactus'}
                    />
                </div>
            </div>
            {/* Send icon background color and img to component */}
            <div
                className={styles['banner-aboutsanctu-cards-container']}
                ref={ref}
            >
                {windowWidth > 768
                    ? trailSprings.map((spring, index) => (
                          <animated.div
                              key={index}
                              style={{
                                  ...spring,
                                  height: '100%',
                              }}
                          >
                              <CardAboutSanctu
                                  title={cardData[index].title}
                                  description={cardData[index].description}
                                  img={cardData[index].img}
                                  backgroundColor={
                                      cardData[index].backgroundColor
                                  }
                                  animationStart={
                                      cardData[index].animationStart
                                  }
                                  index={cardData[index].index}
                              />
                          </animated.div>
                      ))
                    : cardData.map((card, index) => (
                          <CardAboutSanctu
                              key={index}
                              title={card.title}
                              description={card.description}
                              img={card.img}
                              backgroundColor={card.backgroundColor}
                              animationStart={card.animationStart}
                              index={card.index}
                          />
                      ))}
            </div>
        </div>
    )
}

export default BannerAboutSanctu
