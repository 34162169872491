import React from 'react'
import { i18n } from '../../lib/i18n/i18n'
import ButtonDefault from '../ButtonDefault/ButtonDefault'
import styles from './BannerGuardians.module.css'
import { motion } from 'framer-motion'
import useButtonWidth from '../../hooks/useButtonWidth'

const BannerGuardians = () => {
    const buttonWidth = useButtonWidth()

    return (
        <div className={styles['banner-guardians-container']}>
            <div className={styles['banner-guardians-main-content']}>
                <div className={styles['banner-guardians-content']}>
                    <h1>{i18n.t('BannerGuardians.Title')}</h1>
                    <p className={styles['banner-guardians-p']}>
                        {i18n.t('BannerGuardians.Content')}
                    </p>
                    <motion.div
                        initial={{ scaleX: 0 }}
                        animate={{ scaleX: 1 }}
                        transition={{ duration: 0.8, delay: 0.8 }}
                        style={{ originX: 0 }}
                    >
                        <ButtonDefault
                            height={56}
                            text={i18n.t('BannerGuardians.Button')}
                            to="#reflorestation"
                            width={buttonWidth}
                        />
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default BannerGuardians
