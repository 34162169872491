import BannerFaqs from '../../components/BannerFaqs/BannerFaqs'
import BannerOneOfUs from '../../components/BannerOneOfUs/BannerOneOfUs'
import Footer from '../../components/Footer/Footer'
import BannerNoBackground from '../../components/BannerNoBackground/BannerNoBackground'
import Nav from '../../components/Nav/Nav'
import styles from './Faqs.module.css'
import { i18n } from '../../lib/i18n/i18n'

const Faqs = () => {
    return (
        <div className={styles['snap-control-css']}>
            <div className={styles['snap-section-start']}>
                <Nav />
                <BannerNoBackground title={i18n.t('BannerFaqs.Title')} />
            </div>
            <div className={styles['container']}>
                <div className={styles['snap-section-start']}>
                    <BannerFaqs />
                </div>
            </div>
            <div className={styles['snap-section-center']}>
                <BannerOneOfUs />
            </div>
            <div className={styles['snap-section-end']}>
                <Footer />
            </div>
        </div>
    )
}

export default Faqs
