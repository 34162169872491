import { i18n } from '../../lib/i18n/i18n'
import styles from './Slider.module.css'

interface SliderProps {
    maxValue: number
    sliderValue: number
    onSliderChange?: (value: number) => void
    style?: {}
}

const Slider: React.FC<SliderProps> = ({
    maxValue,
    sliderValue,
    onSliderChange,
    style: sliderStyle,
}) => {
    const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        const newValue = parseInt(value)
        if (onSliderChange) {
            onSliderChange(newValue)
            sliderValue = newValue
        }
    }

    return (
        <div className={styles['slider-main-container']}>
            <div>
                <p>{i18n.t('BannerReforestation.HowMuch')}</p>
            </div>
            <div className={styles['slider-value']}>
                <p>{sliderValue} Ha</p>
            </div>
            <input
                className={styles['slider']}
                style={sliderStyle}
                type="range"
                min={0}
                max={maxValue !== undefined ? maxValue : 100}
                step={1}
                value={sliderValue !== undefined ? sliderValue : 50}
                onChange={handleSliderChange}
                readOnly
            />
            <div className={styles['slider-text']}>
                <p>4 Ha</p>
                <p>{maxValue !== undefined ? maxValue : 100} Ha</p>
            </div>
        </div>
    )
}

export default Slider
