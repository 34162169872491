import React, { useCallback, useState } from 'react'

const useHorizontalScroll = () => {
    const containerRef = React.useRef<HTMLDivElement>(null)

    // States to be used on scroll buttons opacity
    const [leftOpacity, setLeftOpacity] = useState(1)
    const [rightOpacity, setRightOpacity] = useState(1)

    // Function to check the current horizontal scroll position and adjust the opacity
    const checkScrollPosition = useCallback(() => {
        if (containerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } =
                containerRef.current

            setLeftOpacity(scrollLeft > 0 ? 1 : 0.5)
            setRightOpacity(
                scrollLeft + clientWidth < scrollWidth - 1 ? 1 : 0.5
            )
        }
    }, [containerRef])

    React.useEffect(() => {
        const currentRef = containerRef.current

        if (currentRef) {
            currentRef.addEventListener('scroll', checkScrollPosition)
            checkScrollPosition()

            return () =>
                currentRef.removeEventListener('scroll', checkScrollPosition)
        }
    }, [checkScrollPosition])

    // Functions to handle scroll
    const handleScrollLeft = () => {
        const el = containerRef.current
        if (el) {
            const newScrollPosition = Math.max(el.scrollLeft - 270, 0)
            el.scroll({ left: newScrollPosition, behavior: 'smooth' })
        }
    }

    const handleScrollRight = () => {
        const el = containerRef.current
        if (el) {
            const maxScrollLeft = el.scrollWidth - el.clientWidth
            const newScrollPosition = Math.min(
                el.scrollLeft + 270,
                maxScrollLeft
            )
            el.scroll({ left: newScrollPosition, behavior: 'smooth' })
        }
    }

    return {
        containerRef,
        handleScrollLeft,
        handleScrollRight,
        leftOpacity,
        rightOpacity,
    }
}

export default useHorizontalScroll
