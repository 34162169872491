import React, { useCallback, useState } from 'react'

const useHorizontalScroll3Cards = () => {
    const containerRef = React.useRef<HTMLDivElement>(null)

    // States to be used on scroll buttons opacity
    const [leftOpacity, setLeftOpacity] = useState(1)
    const [rightOpacity, setRightOpacity] = useState(1)

    // Function to check the current horizontal scroll position and adjust the opacity
    const checkScrollPosition = useCallback(() => {
        if (containerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } =
                containerRef.current

            setLeftOpacity(scrollLeft > 0 ? 1 : 0.5)
            setRightOpacity(
                scrollLeft + clientWidth < scrollWidth - 1 ? 1 : 0.5
            )
        }
    }, [containerRef])

    React.useEffect(() => {
        // Store a reference to avoid potential future null values
        // during cleanup
        const currentRef = containerRef.current

        if (currentRef) {
            // Add an event listener to the scroll event
            currentRef.addEventListener('scroll', checkScrollPosition)
            // Check initial scroll position when component mounts
            checkScrollPosition()

            // Cleanup: Remove the event listener when component unmounts
            return () =>
                currentRef.removeEventListener('scroll', checkScrollPosition)
        }
    }, [checkScrollPosition, containerRef])

    // Functions to handle scroll
    const handleScrollLeft = () => {
        const el = containerRef.current
        if (el) {
            const cardWidth = el.clientWidth
            const newScrollPosition = Math.max(
                el.scrollLeft - (cardWidth + 6),
                0
            )
            el.scroll({ left: newScrollPosition, behavior: 'smooth' })
        }
    }

    const handleScrollRight = () => {
        const el = containerRef.current
        if (el) {
            const cardWidth = el.clientWidth
            const maxScrollLeft = el.scrollWidth - el.clientWidth
            const newScrollPosition = Math.min(
                el.scrollLeft + (cardWidth + 6),
                maxScrollLeft
            )
            el.scroll({ left: newScrollPosition, behavior: 'smooth' })
        }
    }

    return {
        containerRef,
        handleScrollLeft,
        handleScrollRight,
        leftOpacity,
        rightOpacity,
    }
}

export default useHorizontalScroll3Cards
