// Regex for CPF Mask
export const cpfMask = (value: string): string => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

// Regex for Phone Mask
export const phoneMask = (value: string): string => {
    return value
        .replace(/\D+/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
        .replace(/(-\d{4})\d+?$/, '$1')
}

// Regex for CAR Mask
export const carMask = (value: string): string => {
    if (!value) return ''
    const uppercasedValue = value.toUpperCase()
    if (value.length <= 2) {
        return uppercasedValue.replace(/[0-9]/g, '')
    }
    if (/^[A-Za-z]{3}$/.test(uppercasedValue)) {
        return uppercasedValue.slice(0, -1)
    }
    if (value.length <= 11) {
        return uppercasedValue
            .replace(/([A-Za-z]{2})([0-9]{1})/, '$1-$2')
            .replace(/([0-9A-Za-z]{7})([0-9A-Za-z]{1})/, '$1-$2')
    }
    return uppercasedValue.replace(/([0-9A-Za-z]{4})([0-9A-Za-z]{4})/g, '$1.$2')
}
