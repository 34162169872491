import styles from './ButtonNoBackground.module.css'
import Arrow_right from '../../assets/images/ButtonNoBackground.svg'

// Component used on BannerOurServices, BannerOurTech, BannerAboutSanctu, BannerFaqs, BannerAsweredQuestions

interface ButtonNoBackgroundProps {
    text: string
    backgroundColor?: string
    to?: string | null
}

const ButtonNoBackground: React.FC<ButtonNoBackgroundProps> = ({
    text,
    backgroundColor,
    to,
}) => {
    const handleClick = () => {
        if (to) {
            window.location.href = to
        }
    }
    return (
        <div
            className={styles['buttonnoback-main-container']}
            style={{ backgroundColor: backgroundColor }}
            onClick={handleClick}
        >
            {text}
            <img src={Arrow_right} alt="Arrow right icon" />
        </div>
    )
}

export default ButtonNoBackground
