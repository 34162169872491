import styles from './BannerOurValues.module.css'
import Badge from '../Badge/Badge'
import { i18n } from '../../lib/i18n/i18n'
import BannerOurValuesCard1 from '../../assets/images/BannerOurValuesCard1.svg'
import BannerOurValuesCard2 from '../../assets/images/BannerOurValuesCard2.svg'
import BannerOurValuesCard3 from '../../assets/images/BannerOurValuesCard3.svg'
import BannerOurValuesCard4 from '../../assets/images/BannerOurValuesCard4.svg'
import BannerOurValuesCard5 from '../../assets/images/BannerOurValuesCard5.svg'
import CardOurValues from './CardOurValues'
import ArrowRight from '../../assets/images/BannerCarbonCreditScoreArrowRight.svg'
import ArrowLeft from '../../assets/images/BannerCarbonCreditScoreArrowLeft.svg'
import useHorizontalScroll from '../../hooks/useHorizontalScroll'
import { useEffect, useRef, useState } from 'react'
import useWindowWidth from '../../hooks/useWindowWidth'

const BannerOurValues = () => {
    // General data for cards
    const cardValues = [
        {
            title: i18n.t('BannerOurValues.Card1Title'),
            description: i18n.t('BannerOurValues.Card1Content'),
            img: BannerOurValuesCard1,
            backgroundColor: '#C2FBC0',
            key: '10',
        },
        {
            title: i18n.t('BannerOurValues.Card2Title'),
            description: i18n.t('BannerOurValues.Card2Content'),
            img: BannerOurValuesCard2,
            backgroundColor: '#AAF8A8',
            key: '22',
        },
        {
            title: i18n.t('BannerOurValues.Card3Title'),
            description: i18n.t('BannerOurValues.Card3Content'),
            img: BannerOurValuesCard3,
            backgroundColor: '#83DC81',
            key: '30',
        },
        {
            title: i18n.t('BannerOurValues.Card4Title'),
            description: i18n.t('BannerOurValues.Card4Content'),
            img: BannerOurValuesCard4,
            backgroundColor: '#76B86D',
            key: '40',
        },
        {
            title: i18n.t('BannerOurValues.Card5Title'),
            description: i18n.t('BannerOurValues.Card5Content'),
            img: BannerOurValuesCard5,
            backgroundColor: '#354333',
            key: '50',
        },
    ]

    const [cardsAnimation, setCardsAnimation] = useState(
        new Array(cardValues.length).fill(false)
    )
    const cardRefs = useRef<Array<HTMLDivElement | null>>([])
    const windowWidth = useWindowWidth()

    // Animate cards when they are visible on the screen
    useEffect(() => {
        if (windowWidth < 1080) {
            return
        }
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const index = Number(
                        (entry.target as HTMLElement).dataset.index
                    )
                    setCardsAnimation((prev) => {
                        const newAnimations = [...prev]
                        newAnimations[index] = entry.isIntersecting
                        return newAnimations
                    })
                })
            },
            {
                root: null,
                threshold: 1,
            }
        )

        const currentRefs = cardRefs.current
        currentRefs.forEach((card, index) => {
            if (card) {
                card.dataset.index = index.toString()
                observer.observe(card)
            }
        })

        return () => {
            currentRefs.forEach((card) => {
                if (card) observer.unobserve(card)
            })
        }
    }, [cardsAnimation, windowWidth])

    // Imported hook to handle horizontal scroll
    const {
        containerRef,
        handleScrollLeft,
        handleScrollRight,
        leftOpacity,
        rightOpacity,
    } = useHorizontalScroll()

    return (
        <div className={styles['bannerourvalues-main-container']}>
            <div className={styles['bannerourvalues-text-content']}>
                <Badge text={i18n.t('BannerOurValues.Badge')} />
                <h2>{i18n.t('BannerOurValues.Title')}</h2>
            </div>
            <div
                className={styles['bannerourvalues-cards-grid']}
                ref={containerRef}
            >
                {cardValues.map((card, index) => (
                    <CardOurValues
                        key={card.key}
                        index={index}
                        ref={(el) => (cardRefs.current[index] = el)}
                        title={card.title}
                        description={card.description}
                        img={card.img}
                        backgroundColor={card.backgroundColor}
                        animate={cardsAnimation[index]}
                    />
                ))}
            </div>
            <div className={styles['bannerourvalues-buttonscroll']}>
                <img
                    src={ArrowLeft}
                    alt="Seta para esquerda"
                    onClick={handleScrollLeft}
                    style={{ opacity: leftOpacity }}
                />
                <img
                    src={ArrowRight}
                    alt="Seta para direita"
                    onClick={handleScrollRight}
                    style={{ opacity: rightOpacity }}
                />
            </div>
        </div>
    )
}

export default BannerOurValues
