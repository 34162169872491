import { i18n } from '../../lib/i18n/i18n'
import PolicySection from './PolicySection'
import styles from './BannerPolicy.module.css'

const BannerPolicy = () => {
    const policySectionData = {
        title: i18n.t(`PolicySection.Title`),
        content: i18n.t(`PolicySection.Content`),
        subTitle: i18n.t(`PolicySection.SubTitle`),
        subList: i18n.t(`PolicySection.SubList`),
        subListContent: i18n.t(`PolicySection.SubListContent`),
    }

    return (
        <div className={styles['banner-policy-container']}>
            <div className={styles['banner-policy-section-container']}>
                <PolicySection policyData={policySectionData} />
                <PolicySection policyData={policySectionData} />
            </div>
        </div>
    )
}

export default BannerPolicy
