import Footer from '../../components/Footer/Footer'
import Nav from '../../components/Nav/Nav'
import BannerOurServices from '../../components/BannerOurServices/BannerOurServices'
import BannerBuyCredits from '../../components/BannerBuyCredits/BannerBuyCredits'
import BannerOneOfUs from '../../components/BannerOneOfUs/BannerOneOfUs'
import BannerHero from '../../components/BannerHero/BannerHero'
import BannerAboutSanctu from '../../components/BannerAboutSanctu/BannerAboutSanctu'
import BannerOurTech from '../../components/BannerOurTech/BannerOurTech'
import BannerVideo from '../../components/BannerVideo/BannerVideo'
import BannerOurGoals from '../../components/BannerOurGoals/BannerOurGoals'
import BannerReforestation from '../../components/BannerReforestation/BannerReforestation'
import styles from './Home.module.css'
import useWindowHeight from '../../hooks/useWindowHeight'
import useWindowWidth from '../../hooks/useWindowWidth'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { i18n } from '../../lib/i18n/i18n'
import { useEffect, useState } from 'react'

const Home = () => {
    const windowHeight = useWindowHeight()
    const windowWidth = useWindowWidth()

    const [android, setAndroid] = useState(false)
    useEffect(() => {
        const isAndroid = /Android/i.test(navigator.userAgent)
        if (isAndroid) {
            setAndroid(true)
        } else {
            setAndroid(false)
        }
    }, [])

    return (
        <HelmetProvider>
            <div>
                <Helmet>
                    <title>{i18n.t('Metadata.HomeTitle')}</title>
                    <meta
                        name="description"
                        content={i18n.t('Metadata.HomeDescription')}
                    />
                </Helmet>
                <div
                    className={
                        android
                            ? styles['snap-control-css-android']
                            : styles['snap-control-css']
                    }
                >
                    <div className={styles['snap-section-navbanner']}>
                        <Nav />
                        <BannerHero />
                    </div>
                    <div className={styles['container']}>
                        <div
                            className={`${
                                styles['snap-section-reforestation']
                            } ${
                                windowHeight < 630 && windowWidth < 450
                                    ? styles['snap-start']
                                    : ''
                            }`}
                        >
                            <BannerReforestation />
                        </div>
                        <div className={styles['snap-section-video']}>
                            <BannerVideo />
                        </div>
                        <div className={styles['snap-section-ourservices']}>
                            <BannerOurServices />
                        </div>
                        <div className={styles['snap-section-ourtech']}>
                            <BannerOurTech />
                        </div>
                        <div className={styles['snap-section-aboutus']}>
                            <BannerAboutSanctu />
                        </div>
                        <div className={styles['snap-section-ourgoals']}>
                            <BannerOurGoals />
                        </div>
                        <div className={styles['snap-section-buy']}>
                            <BannerBuyCredits />
                        </div>
                    </div>
                    <div className={styles['snap-section-oneofus']}>
                        <BannerOneOfUs />
                    </div>
                    <div className={styles['snap-section-footer']}>
                        <Footer />
                    </div>
                </div>
            </div>
        </HelmetProvider>
    )
}

export default Home
