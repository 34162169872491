import React from 'react'
import { i18n } from '../../lib/i18n/i18n'
import styles from './BannerContactUs.module.css'
import ButtonDefault from '../ButtonDefault/ButtonDefault'
import { motion } from 'framer-motion'
import useButtonWidth from '../../hooks/useButtonWidth'

const BannerContactUs = () => {
    const buttonWidth = useButtonWidth()

    return (
        <div className={styles['contactus-container']}>
            <div className={styles['contactus-main-content']}>
                <div className={styles['contactus-content']}>
                    <h2>{i18n.t('BannerContactUs.Title')}</h2>
                    <p className={styles['bannerbuycreditspages-p']}>
                        {i18n.t('BannerContactUs.Content')}
                    </p>
                    <motion.div
                        initial={{ scaleX: 0 }}
                        animate={{ scaleX: 1 }}
                        transition={{ duration: 0.8, delay: 0.8 }}
                        style={{ originX: 0, width: '100%' }}
                    >
                        <ButtonDefault
                            height={56}
                            text={i18n.t('BannerContactUs.Button')}
                            to="/joinus"
                            width={buttonWidth}
                        />
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default BannerContactUs
