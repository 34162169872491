import styles from './BannerOurTech.module.css'
import ButtonNoBackground from '../ButtonNoBackground/ButtonNoBackground'
import CardOurTech from './CardOurTech'
import { useRef } from 'react'
import { i18n } from '../../lib/i18n/i18n'
import useWindowWidth from '../../hooks/useWindowWidth'
import React from 'react'
import BadgeNoAni from '../BadgeNoAni/BadgeNoAni'

const BannerOurTech = () => {
    // General data for cards rendered
    const cards = [
        {
            title: i18n.t(`BannerOurTech.TitleCard1`),
            content1: i18n.t(`BannerOurTech.ContentCard1Line1`),
            content2: i18n.t(`BannerOurTech.ContentCard1Line2`),
        },
        {
            title: i18n.t(`BannerOurTech.TitleCard2`),
            content1: i18n.t(`BannerOurTech.ContentCard2Line1`),
            content2: i18n.t(`BannerOurTech.ContentCard2Line2`),
        },
        {
            title: i18n.t(`BannerOurTech.TitleCard3`),
            content1: i18n.t(`BannerOurTech.ContentCard3Line1`),
            content2: i18n.t(`BannerOurTech.ContentCard3Line2`),
        },
        {
            title: i18n.t(`BannerOurTech.TitleCard4`),
            content1: i18n.t(`BannerOurTech.ContentCard4Line1`),
            content2: i18n.t(`BannerOurTech.ContentCard4Line2`),
        },
        {
            title: i18n.t(`BannerOurTech.TitleCard5`),
            content2: i18n.t(`BannerOurTech.ContentCard5Line2`),
        },
    ]

    const cardTechImages = [
        require('../../assets/images/BannerOurTechCard1.jpg'),
        require('../../assets/images/BannerOurTechCard2.jpg'),
        require('../../assets/images/BannerOurTechCard3.jpg'),
        require('../../assets/images/BannerOurTechCard4.jpg'),
        require('../../assets/images/BannerOurTechCard5.jpg'),
    ]

    const cardTechDesktopImages = [
        require('../../assets/images/BannerOurTechCard1-mobile.jpg'),
        require('../../assets/images/BannerOurTechCard2-mobile.jpg'),
        require('../../assets/images/BannerOurTechCard3-mobile.jpg'),
        require('../../assets/images/BannerOurTechCard4-mobile.jpg'),
        require('../../assets/images/BannerOurTechCard5-desktop.jpg'),
    ]

    const cardTechMobileImages = [
        require('../../assets/images/BannerOurTechCard1-mobile.jpg'),
        require('../../assets/images/BannerOurTechCard2-mobile.jpg'),
        require('../../assets/images/BannerOurTechCard3-mobile.jpg'),
        require('../../assets/images/BannerOurTechCard4-mobile.jpg'),
        require('../../assets/images/BannerOurTechCard5.jpg'),
    ]

    const cardRefs = useRef<Array<React.RefObject<HTMLDivElement>>>(
        cards.map(() => React.createRef())
    )
    const bannerRef = useRef(null)
    const windowWidth = useWindowWidth()

    return (
        <div className={styles['bannerourtech-container']} ref={bannerRef}>
            <div className={styles['bannerourtech-content']}>
                <div className={styles['bannerourtech-header-container']}>
                    <BadgeNoAni
                        text="Sanctu OS"
                        backgroundColor="var(--white-color)"
                    />
                    <div className={styles['bannerourtech-header-content']}>
                        <div className={styles['bannerourtech-header-text']}>
                            <h2>{i18n.t('BannerOurTech.Title')}</h2>
                            <p>{i18n.t('BannerOurTech.Content')}</p>
                        </div>
                        <div className={styles['bannerourtech-button']}>
                            <ButtonNoBackground
                                text={i18n.t('BannerOurTech.Button')}
                                backgroundColor="var(--white-color)"
                                to={'/contactus'}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles['bannerourtech-cards-grid']}>
                    {cards.map((card, index) => (
                        <div ref={cardRefs.current[index]} key={index}>
                            <CardOurTech
                                index={index + 1}
                                title={card.title}
                                content1={card.content1}
                                content2={card.content2}
                                img={
                                    windowWidth < 500
                                        ? cardTechMobileImages[index]
                                        : windowWidth > 500 && windowWidth < 650
                                        ? cardTechDesktopImages[index]
                                        : windowWidth > 768 &&
                                          windowWidth < 1250
                                        ? cardTechDesktopImages[index]
                                        : cardTechImages[index]
                                }
                                key={index + 1}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default BannerOurTech
