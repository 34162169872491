import { useTranslation } from 'react-i18next'
import styles from './BannerSuccess.module.css'
import ButtonDefaultDark from '../ButtonDefaultDark/ButtonDefaultDark'

const BannerSuccess = () => {
    const { i18n } = useTranslation()
    return (
        <div className={styles['success-container']}>
            <h2>{i18n.t('BannerSuccess.Title')}</h2>
            <p>{i18n.t('BannerSuccess.Content')}</p>
            <ButtonDefaultDark
                height={56}
                text={i18n.t('BannerSuccess.Button')}
                to="/"
            />
        </div>
    )
}

export default BannerSuccess
