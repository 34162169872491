import styles from './CardOurValues.module.css'
import { motion } from 'framer-motion'
import React, { forwardRef, useState, useEffect } from 'react'

// Component used on BannerOurValues

interface CardOurValuesProps {
    title?: string
    description: string
    img?: string
    backgroundColor?: string
    animate?: boolean
    index?: any
}

// Based on index prop, we set a different delay for each card
const CardOurValues = forwardRef<HTMLDivElement, CardOurValuesProps>(
    ({ title, description, img, backgroundColor, animate, index }, ref) => {
        const baseDelay = 1
        const delay = index * baseDelay

        const imageVariants = {
            animate: {
                scale: [1, 1.6, 1],
                transition: {
                    duration: 1.6,
                    delay: delay,
                },
            },
            initial: { scale: 1 },
        }

        // Replace \n with <br /> to break lines on html
        const descriptionWithBreaks = {
            __html: description.replace(/\n/g, '<br />'),
        }

        const [isLargeScreen, setIsLargeScreen] = useState(
            window.innerWidth > 1080
        )

        useEffect(() => {
            const handleResize = () => {
                setIsLargeScreen(window.innerWidth > 1080)
            }

            window.addEventListener('resize', handleResize)

            return () => window.removeEventListener('resize', handleResize)
        }, [])

        return (
            <div ref={ref} className={styles['ourvalues-card-container']}>
                <div
                    className={styles['ourvalues-card-image']}
                    style={{ backgroundColor: backgroundColor }}
                >
                    <motion.img
                        src={img}
                        alt="Card About Sanctu img"
                        variants={imageVariants}
                        animate={animate ? 'animate' : 'initial'}
                        transition={{ duration: 1 }}
                    />
                </div>
                {isLargeScreen ? (
                    <div className={styles['ourvalues-card-content']}>
                        <h2>{title}</h2>
                        <p dangerouslySetInnerHTML={descriptionWithBreaks}></p>
                    </div>
                ) : (
                    <>
                        <div
                            className={styles['ourvalues-card-content-h2-size']}
                        >
                            <h2>{title}</h2>
                        </div>
                        <div className={styles['ourvalues-card-content']}>
                            <p
                                dangerouslySetInnerHTML={descriptionWithBreaks}
                            ></p>
                        </div>
                    </>
                )}
            </div>
        )
    }
)

export default CardOurValues
