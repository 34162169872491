import styles from './PolicySection.module.css'

interface PolicySectionProps {
    policyData: {
        title: string
        content: string
        subTitle: string
        subList: string
        subListContent: string
    }
}

const PolicySection: React.FC<PolicySectionProps> = ({ policyData }) => {
    return (
        <div className={styles['policy-section-content-text']}>
            <h3>{policyData.title}</h3>
            <p>{policyData.content}</p>
            <span>{policyData.subTitle}</span>
            <p>
                <span>{policyData.subList}</span>
                {policyData.subListContent}
            </p>
        </div>
    )
}

export default PolicySection
