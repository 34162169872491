import Nav from '../../components/Nav/Nav'
import Footer from '../../components/Footer/Footer'
import BannerJoinUs from '../../components/BannerJoinUs/BannerJoinUs'
import BannerOurValues from '../../components/BannerOurValues/BannerOurValues'
import JobsBoard from '../../components/BannerJobsBoard/BannerJobsBoard'
import styles from './JoinUs.module.css'
import BannerOurPeople from '../../components/BannerOurPeople/BannerOurPeople'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { i18n } from '../../lib/i18n/i18n'
import { useEffect, useState } from 'react'

const JoinUs = () => {
    const [android, setAndroid] = useState(false)
    useEffect(() => {
        const isAndroid = /Android/i.test(navigator.userAgent)
        if (isAndroid) {
            setAndroid(true)
        } else {
            setAndroid(false)
        }
    }, [])

    return (
        <HelmetProvider>
            <div>
                <Helmet>
                    <title>{i18n.t('Metadata.JoinUsTitle')}</title>
                    <meta
                        name="description"
                        content={i18n.t('Metadata.JoinUsDescription')}
                    />
                </Helmet>
                <div
                    className={
                        android
                            ? styles['snap-control-css-android']
                            : styles['snap-control-css']
                    }
                >
                    <div className={styles['snap-section-navbanner']}>
                        <Nav />
                        <BannerJoinUs />
                    </div>
                    <div className={styles['container']}>
                        <div className={styles['snap-section-ourvalues']}>
                            <BannerOurValues />
                        </div>
                        <div className={styles['snap-section-ourpeople']}>
                            <BannerOurPeople />
                        </div>
                        <div className={styles['snap-section-jobsboard']}>
                            <JobsBoard />
                        </div>
                    </div>
                    <div className={styles['snap-section-footer']}>
                        <Footer />
                    </div>
                </div>
            </div>
        </HelmetProvider>
    )
}

export default JoinUs
