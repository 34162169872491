// import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom' TODO
import styles from './ButtonDefault.module.css'
import Sanctu_logo from '../../assets/images/ButtonDefaultSanctuLogo.svg'

// Component used on BannerHero, Banner404

interface ButtonDefaultProps {
    width?: string | number
    height: string | number
    text: string
    to: string
}

const ButtonGuardian: React.FC<ButtonDefaultProps> = ({
    width,
    height,
    text,
    to,
}) => {
    const handleClick = () => {
        // Case settings for each button
        let targetElement = null
        let targetBlock: ScrollLogicalPosition | undefined = undefined
        switch (to) {
            case '#reflorestation':
                targetElement = document.getElementById('reflorestation')
                targetBlock = 'end'
                break

            case '#get-touch':
                targetElement = document.getElementById('get-touch')
                targetBlock = 'start'
                break

            case '#jobs-board':
                targetElement = document.getElementById('jobs-board')
                targetBlock = 'start'
                break
            default:
                window.location.href = to
                break
        }

        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: 'smooth',
                block: targetBlock || 'start',
            })
        }
        // Remove the fragment identifier from the URL
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        )
    }

    return (
        <button
            className={styles['button-default']}
            style={{
                width: width,
                height: height,
            }}
            onClick={handleClick}
        >
            <img src={Sanctu_logo} alt="Sanctu logo" />
            <p style={{ marginRight: '40px' }}>{text}</p>
        </button>
    )
}

export default ButtonGuardian
