import styles from './CardCarbonCreditScore.module.css'
import BadgeNoAni from '../BadgeNoAni/BadgeNoAni'
import SquareCardScore from './SquareCardScore'
import { useEffect, useRef, useState } from 'react'

// Component used on the Carbon Credit Score page

interface CardCarbonCreditScoreProps {
    badgeText: string
    titleText: string
    contentText: string
    squareIndex: number
}

const CardCarbonCreditScore: React.FC<CardCarbonCreditScoreProps> = ({
    badgeText,
    titleText,
    contentText,
    squareIndex,
}) => {
    const containerRef2 = useRef(null)
    const [startAnimation, setStartAnimation] = useState(false)

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.6,
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        setStartAnimation(true)
                    }, 500)

                    observer.disconnect()
                }
            })
        }, options)

        if (containerRef2.current) {
            observer.observe(containerRef2.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [])

    return (
        <div
            className={styles['cardcreditscore-main-container']}
            ref={containerRef2}
        >
            <div className={styles['cardcreditscore-title']}>
                <div className={styles['cardcreditscore-badge']}>
                    <BadgeNoAni text={badgeText} />
                </div>
                <h3>{titleText}</h3>
            </div>
            <div className={styles['cardcreditscore-content']}>
                <p>{contentText}</p>
                <div className={styles['cardcreditscore-scoreUI']}>
                    {Array.from({ length: 6 }, (_, i) => (
                        <SquareCardScore
                            key={i}
                            backgroundColor={
                                i < squareIndex - 1
                                    ? 'var(--brand-color)'
                                    : i === squareIndex - 1
                                    ? 'var(--brand-last-color)'
                                    : 'var(--neutral-color)'
                            }
                            scale={i < squareIndex - 1 ? 1 : 1}
                            delay={i * 0.15}
                            animation={startAnimation}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CardCarbonCreditScore
