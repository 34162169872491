import styles from '../BannerCookiesPolicies/BannerCookiesPolicies.module.css'
import { i18n } from '../../lib/i18n/i18n'
import Linkify from 'react-linkify'
import React from 'react'

// Settings to override the default Linkify component, open up the link in a new tab
const customLinkRenderer = (href: string, text: string, key: any) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
    </a>
)

const BannerCookiesPolicies = () => {
    return (
        <div className={styles['banner-cookies-container']}>
            <div className={styles['banner-cookies-text-content']}>
                <h3>{i18n.t('BannerCookies.Subtitle1')}</h3>
                <p>
                    {/* Split the content by newlines and render each line with a
                    break. */}
                    {i18n
                        .t('BannerCookies.Content1')
                        .split('\n')
                        .map((line: string, index: number) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                </p>
                <h3>{i18n.t('BannerCookies.Subtitle2')}</h3>
                <p>
                    {i18n
                        .t('BannerCookies.Content2')
                        .split('\n')
                        .map((line: string, index: number) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                </p>
                <h3>
                    {i18n
                        .t('BannerCookies.Subtitle3')
                        .split('\n')
                        .map((line: string, index: number) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                </h3>
                <ul>
                    <li>{i18n.t('BannerCookies.LI1')}</li>
                    <li>{i18n.t('BannerCookies.LI2')}</li>
                    <li>{i18n.t('BannerCookies.LI3')}</li>
                </ul>
                <h3>{i18n.t('BannerCookies.Subtitle4')}</h3>
                <p>
                    {/* Use Linkify to automatically detect and convert URLs in the text into links. */}
                    <Linkify componentDecorator={customLinkRenderer}>
                        {i18n
                            .t('BannerCookies.Content4')
                            .split('\n')
                            .map((line: string, index: number) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                    </Linkify>
                </p>
                <h3>{i18n.t('BannerCookies.Subtitle5')}</h3>
                <p
                    style={{
                        scrollSnapAlign: 'end',
                        scrollSnapStop: 'normal',
                    }}
                >
                    {i18n
                        .t('BannerCookies.Content5')
                        .split('\n')
                        .map((line: string, index: number) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                </p>
            </div>
        </div>
    )
}

export default BannerCookiesPolicies
