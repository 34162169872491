// Import Axios library
import axios from 'axios'

// Define the base URL for the Axios instance, for intance local host
const BASE_URL = 'https://sanctulandingpageapi.azurewebsites.net/'

// Create and export a default Axios instance with the base URL
export default axios.create({
    baseURL: BASE_URL,
})

// Create and export an Axios instance for private requests
// This instance has some custom headers and allows sending cookies
export const axiosPrivate = axios.create({
    baseURL: BASE_URL, // Set the base URL
    headers: { 'Content-Type': 'application/json' }, // Add JSON content-type header
    withCredentials: true, // Allow sending cookies for cross-origin requests
})
