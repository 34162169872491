import { i18n } from '../../lib/i18n/i18n'
import ButtonDefault from '../ButtonDefault/ButtonDefault'
import styles from './Banner404.module.css'

const Banner404 = () => {
    return (
        <div className={styles['error-404-container']}>
            <div className={styles['error-content']}>
                <div className={styles['error-text']}>
                    <h2 className={styles['bannerhero-title']}>404</h2>
                    <p>{i18n.t('Banner404.Content')}</p>
                </div>
                <ButtonDefault
                    width={624}
                    height={56}
                    text={i18n.t('Banner404.Button')}
                    to="/"
                />
            </div>
        </div>
    )
}

export default Banner404
