import styles from './BadgeNoAni.module.css'

interface BadgeNoAniProps {
    text: string
    backgroundColor?: string
}

const BadgeNoAni: React.FC<BadgeNoAniProps> = ({ text, backgroundColor }) => {
    return (
        <div
            className={styles['badge-main-container']}
            style={{ backgroundColor: backgroundColor }}
        >
            {text}
        </div>
    )
}

export default BadgeNoAni
