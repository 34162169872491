// Import external libraries
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

// Import internal libraries
import axios from '../../lib/api/axios'
import { i18n } from '../../lib/i18n/i18n'
import styles from './ModalGuardian.module.css'
import InputField from '../InputField/InputField'
import { cpfMask } from '../../hooks/mask'
import validateField from '../../hooks/validateForm'
import ButtonDefaultDark from '../ButtonDefaultDark/ButtonDefaultDark'
import Badge from '../Badge/Badge'
import SucessImage from '../../assets/images/ModalGuardianSuccessPic.jpg'
import ButtonWhatsApp from '../ButtonWhatsApp/ButtonWhatsApp'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './PhoneStyle.css'
import CloseIcon from '../../assets/images/ModalCloseIcon.svg'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import { ThreeDots } from 'react-loader-spinner'
import { AnimatePresence, motion } from 'framer-motion'

interface ModalProps {
    show: boolean
    onClose: () => void
    onSubmit: (data: any) => void
    reforestationArea: number
    totalHectares: number
}

interface InputValues {
    first_name: string
    last_name: string
    email: string
    cpf: string
    phone_number: string
}

interface ValidFields {
    first_name: boolean
    last_name: boolean
    email: boolean
    cpf: boolean
    phone_number: boolean
}

export const ModalGuardian: FC<ModalProps> = ({
    show,
    onClose,
    reforestationArea,
    totalHectares,
}) => {
    // API end point to be sent with locally axios setup
    const GUARDIANS = 'guardians/'
    const [phoneFocus, setPhoneFocus] = useState(false)
    const modalContentRef = useRef<HTMLDivElement>(null)
    const [msg, setMsg] = useState('')
    const [errorMessage, setErrorMessage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [validate, setValidate] = useState(false)
    const [inputValues, setInputValues] = useState<InputValues>({
        first_name: '',
        last_name: '',
        email: '',
        cpf: '',
        phone_number: '',
    })

    // Default State to control the input field validity
    const [validFields, setValidFields] = useState<ValidFields>({
        first_name: true,
        last_name: true,
        email: true,
        cpf: true,
        phone_number: true,
    })

    // Array with the field names to check for validity
    const fieldNamesToCheck: Array<keyof ValidFields> = [
        'first_name',
        'last_name',
        'email',
        'cpf',
        'phone_number',
    ]

    // Handle clicks outside the modal
    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (
                modalContentRef.current &&
                !modalContentRef.current.contains(event.target as Node)
            ) {
                // Set the showMessage state to false when the modal is closed, to show the form if user reopen the modal
                setShowMessage(false)
                document.body.classList.remove('hide-scroll-bar')
                onClose()
            }
        },
        [onClose]
    )

    // useEffect to be used on close modal if user click outside modal container
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        setShowMessage(false)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [handleClickOutside])

    // If error message is showing, scroll to top of the page
    useEffect(() => {
        if (showMessage) {
            const topElement = document.getElementById('top')
            if (topElement) {
                topElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
            }
        }
    }, [showMessage, setShowMessage])

    const handleInputChange = (
        fieldName: string,
        value: string | undefined
    ) => {
        // Hide the error message when the user starts typing
        setErrorMessage(false)

        if (value === undefined) {
            return
        }

        if (fieldName === 'cpf') {
            value = cpfMask(value)
        }

        // Validate the field from validateForm.ts
        const isValid = validateField(fieldName, value)

        // Update the state with the value of the input field
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [fieldName]: value,
        }))

        // Update the state with the validity of the input field
        setValidFields((prevValidFields) => ({
            ...prevValidFields,
            [fieldName]: isValid,
        }))
    }

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setValidate(true)

        // New object to store updated valid fields
        const updatedValidFields: ValidFields = { ...validFields }

        // If the user leave a field empty, the field will be marked as invalid
        fieldNamesToCheck.forEach((fieldName) => {
            if (inputValues[fieldName] === '') {
                updatedValidFields[fieldName] = false
            }
        })

        // Update the validFields state with the updated values
        setValidFields(updatedValidFields)

        // Check if all fields are valid
        const allFieldsValid = Object.values(updatedValidFields).every(
            (isValid) => isValid
        )

        // If any field is invalid, don't submit the form
        if (!allFieldsValid) {
            setErrorMessage(true)
            const topElement = document.getElementById('top')
            if (topElement) {
                topElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
            }
            return
        }
        setLoading(true)
        // Using form data to create FORM
        const formData = new FormData(e.target as HTMLFormElement)
        const data = Object.fromEntries(formData)

        data.phone_number = inputValues.phone_number
        // Add reflorestation_size from props to form
        data.reflorestation_size = reforestationArea.toString()

        // Condition to check if land_size is coming from props and add it to the form
        if (totalHectares !== null) {
            data.land_size = totalHectares.toString()
        } else {
            data.land_size = ''
        }

        // After validation, show the success message and hide the form
        setShowMessage(true)

        // Send the data to the API
        try {
            await axios.post(GUARDIANS, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            // Show success message
            setMsg(i18n.t('ModalGuardian.Success'))
        } catch (err: any) {
            if (err.response) {
                switch (err.response.status) {
                    case 400:
                        // Since cpf is the only unique field, we can assume that the error is related to it
                        setMsg(i18n.t('ModalGuardian.400Error'))
                        break
                    case 500:
                        setMsg(i18n.t('ModalGuardian.500Error'))
                        break
                    default:
                        setMsg(i18n.t('ModalGuardian.DefaultError'))
                }
            } else if (err.request) {
                // The request was made, but no response was received
                setMsg(i18n.t('ModalGuardian.NoResponse'))
            } else {
                // Something else happened while setting up the request
                setMsg(i18n.t('ModalGuardian.OtherError'))
            }
        }
        setLoading(false)
    }

    return (
        <AnimatePresence>
            {show ? (
                <div className={styles['modal-background']}>
                    <motion.div
                        className={styles['modal-content']}
                        ref={modalContentRef}
                        initial={{ x: '100%', opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: '100%', opacity: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        <button
                            className={styles['modal-exit-button']}
                            onClick={onClose}
                            id="top"
                        >
                            <img src={CloseIcon} alt="close-icon" />
                        </button>
                        <div className={styles['modal-badge']}>
                            <Badge text={i18n.t('BannerGuardian.Badge')} />
                        </div>
                        {errorMessage ? (
                            <ErrorMessage
                                text={i18n.t('BannerGetInTouch.ErrorMsg')}
                            />
                        ) : null}
                        {showMessage ? (
                            <div className={styles['modal-form-message']}>
                                <h2>{msg}</h2>
                                <img
                                    src={SucessImage}
                                    alt="Farmers reforestation"
                                />
                                <div
                                    className={
                                        styles['modal-form-message-whats']
                                    }
                                >
                                    <p>
                                        {i18n.t('ModalGuardian.MessageWhats')}
                                    </p>
                                    <ButtonWhatsApp
                                        text={i18n.t(
                                            'ModalGuardian.ButtonWhats'
                                        )}
                                        whatsappNumber={'+5511942483414'}
                                    />
                                </div>
                            </div>
                        ) : (
                            <form
                                className={styles['modal-form-container']}
                                onSubmit={handleSubmit}
                            >
                                <h3>{i18n.t('ModalGuardian.Title')}</h3>
                                <InputField
                                    name="first_name"
                                    label={i18n.t('ModalGuardian.First_name')}
                                    value={inputValues.first_name}
                                    valid={
                                        validate ? validFields.first_name : true
                                    }
                                    onInputChange={handleInputChange}
                                />
                                <InputField
                                    name="last_name"
                                    label={i18n.t('ModalGuardian.Last_name')}
                                    value={inputValues.last_name}
                                    valid={
                                        validate ? validFields.last_name : true
                                    }
                                    onInputChange={handleInputChange}
                                />
                                <div
                                    className={
                                        styles['modal-phone-input-container']
                                    }
                                >
                                    <p>
                                        {i18n.t('ModalGuardian.Phone_number')}
                                    </p>
                                    <PhoneInput
                                        country={'br'}
                                        value={inputValues.phone_number}
                                        onChange={(value) =>
                                            handleInputChange(
                                                'phone_number',
                                                value
                                            )
                                        }
                                        containerStyle={{
                                            border: phoneFocus
                                                ? '2px solid var(--dark-color)'
                                                : (validate &&
                                                      !validFields.phone_number &&
                                                      '2px solid #bc4337') ||
                                                  '1px solid var(--dark-color)',
                                        }}
                                        onFocus={() => {
                                            setPhoneFocus(true)
                                        }}
                                        onBlur={() => {
                                            setPhoneFocus(false)
                                        }}
                                        enableSearch={true}
                                        containerClass={
                                            styles['modal-phone-container']
                                        }
                                        inputClass={styles['modal-phone-input']}
                                        buttonClass={
                                            styles['modal-phone-button']
                                        }
                                        dropdownClass={
                                            styles['modal-phone-dropdown']
                                        }
                                        searchClass={
                                            styles['modal-phone-search']
                                        }
                                        disableSearchIcon={true}
                                        searchPlaceholder={i18n.t(
                                            'PhoneInput.SearchCountry'
                                        )}
                                        jumpCursorToEnd={true}
                                        autocompleteSearch={true}
                                        searchNotFound={i18n.t(
                                            'PhoneInput.SearchNotFound'
                                        )}
                                    />
                                </div>
                                <InputField
                                    name="email"
                                    label={i18n.t('ModalGuardian.Email')}
                                    value={inputValues.email}
                                    valid={validate ? validFields.email : true}
                                    onInputChange={handleInputChange}
                                />
                                <InputField
                                    name="cpf"
                                    label={i18n.t('ModalGuardian.CPF')}
                                    value={inputValues.cpf}
                                    valid={validate ? validFields.cpf : true}
                                    onInputChange={handleInputChange}
                                />
                                <ButtonDefaultDark
                                    type={'submit'}
                                    width={'100%'}
                                    height={56}
                                    text={i18n.t('ModalGuardian.Button')}
                                />
                            </form>
                        )}
                        {loading && (
                            <div className={styles['modal-loading-spinner']}>
                                <ThreeDots
                                    visible={true}
                                    height="80"
                                    width="80"
                                    color="var(--pine-color)"
                                    radius="9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div>
                        )}
                    </motion.div>
                </div>
            ) : null}
        </AnimatePresence>
    )
}
