import styles from './CardOurGoals.module.css'

// Component used on BannerOurGoals

interface CardOurGoalsProps {
    img: string
    title: string
    content: string
}
const CardOurGoals: React.FC<CardOurGoalsProps> = ({ img, title, content }) => {
    return (
        <div className={styles['cardourgoals-main-container']}>
            <div className={styles['cardourgoals-icon']}>
                <img src={img} alt="Icon card our goals" />
            </div>
            <div className={styles['cardgoals-content']}>
                <h3>{title}</h3>
                <p dangerouslySetInnerHTML={{ __html: content }}></p>
            </div>
        </div>
    )
}

export default CardOurGoals
