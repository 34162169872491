// external libraries imports
import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

// style imports
import './App.css'

// Pages
import Home from '../pages/HomePage/Home'
import ContactUs from '../pages/ContactUs/ContactUs'
import Page404 from '../pages/ErrorPages/Page404'
import Page500 from '../pages/ErrorPages/Page500'
import BuyCredits from '../pages/BuyCredits/BuyCredits'
import JoinUs from '../pages/JoinUs/JoinUs'

// Components
import Layout from '../lib/auth/Layout/Layout'
import Guardians from '../pages/Guardians/Guardians'
import Faqs from '../pages/Faqs/Faqs'
import Policy from '../pages/Policy/Policy'
import Cookies from '../pages/Cookies/Cookies'
import PageSuccess from '../pages/ErrorPages/PageSuccess'
import { AnimationProvider } from '../hooks/animationContext'

function App() {
    // set up Google Analytics
    const location = useLocation()

    const sendScreenView = () => {
        const width = window.innerWidth
        const height = window.innerHeight

        ReactGA.event({
            category: 'User',
            action: 'Screen Width',
            value: width,
        })

        ReactGA.event({
            category: 'User',
            action: 'Screen Height',
            value: height,
        })
    }

    useEffect(() => {
        sendScreenView()
        window.addEventListener('resiaze', sendScreenView)

        return () => {
            window.removeEventListener('resize', sendScreenView)
        }
    }, [])

    useEffect(() => {
        ReactGA.initialize('G-7WTZWLFWH5')
    }, [])

    // useEffect is called every time the path changes
    useEffect(() => {
        const pagePath = location.pathname + location.search

        // Send the page view to GA with path
        ReactGA.send({ hitType: 'pageview', page: pagePath })
    }, [location])

    useEffect(() => {
        const setFavicon = (darkMode: boolean) => {
            const link = document.querySelector(
                "link[rel*='icon']"
            ) as HTMLLinkElement
            if (link) {
                link.href = darkMode
                    ? '/favicon-dark.ico'
                    : '/favicon-light.ico'
            }
        }

        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
        setFavicon(mediaQuery.matches)

        const handleChange = (e: MediaQueryListEvent) => {
            setFavicon(e.matches)
        }

        mediaQuery.addEventListener('change', handleChange)

        return () => {
            mediaQuery.removeEventListener('change', handleChange)
        }
    }, [])

    return (
        <AnimationProvider>
            <Routes>
                <Route element={<Layout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="guardians" element={<Guardians />} />
                    <Route path="buycredits" element={<BuyCredits />} />
                    <Route path="contactus" element={<ContactUs />} />
                    <Route path="joinus" element={<JoinUs />} />
                    <Route path="faq" element={<Faqs />} />
                    <Route path="policy" element={<Policy />} />
                    <Route path="cookies" element={<Cookies />} />
                    <Route path="success" element={<PageSuccess />} />
                    <Route path="404" element={<Page404 />} />
                    <Route path="500" element={<Page500 />} />
                    {/* This is a catch-all route that directs all non-matching routes to the Home component */}
                    <Route path="*" element={<Home />} />
                </Route>
            </Routes>
        </AnimationProvider>
    )
}

export default App
