import styles from './JobCard.module.css'
import ArrowRight from '../../assets/images/BannerCarbonCreditScoreArrowRight.svg'
import { Link } from 'react-router-dom'

// Component used on BannerJobsBoard

interface JobCardProps {
    title: string
    description: string
    linkUrl: string
}

const JobCard: React.FC<JobCardProps> = ({ title, description, linkUrl }) => {
    return (
        <div className={styles['job-card-container']}>
            <div className={styles['job-card-content']}>
                <h4>{description}</h4>
                <p>{title}</p>
            </div>
            <Link
                to={linkUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={styles['job-card-image-container']}
            >
                <img src={ArrowRight} alt="Seta para direita" />
            </Link>
        </div>
    )
}

export default JobCard
