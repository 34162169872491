import { i18n } from '../../lib/i18n/i18n'
import Badge from '../Badge/Badge'
import JobCard from './JobCard'
import Selector from '../Selector/Selector'
import styles from './BannerJobsBoard.module.css'
import { useEffect, useState } from 'react'
import axios from 'axios'

const JobsBoard = () => {
    interface SelectorOption {
        value: string
        label: string
    }

    interface CardData {
        title: string
        description: string
        linkUrl: string
        officeName: string | null
    }

    const [roleOptions, setRoleOptions] = useState<SelectorOption[]>([])
    const [typeOptions, setTypeOptions] = useState<SelectorOption[]>([])
    const [locationOptions, setLocationOptions] = useState<SelectorOption[]>([])
    const [cardData, setCardData] = useState<CardData[]>([])
    const [selectedRole, setSelectedRole] = useState<string | null>(null)
    const [selectedType, setSelectedType] = useState<string | null>(null)
    const [selectedLocation, setSelectedLocation] = useState<string | null>(
        null
    )
    const [newDetails, setNewDetails] = useState<any[]>([])

    // Fetch all the jobs details from the Greenhouse API
    useEffect(() => {
        const fetchAllJobDetails = async () => {
            try {
                // Fetch all the jobs
                const jobsResponse = await axios.get(
                    'https://boards-api.greenhouse.io/v1/boards/sanctu/jobs'
                )
                const jobs = jobsResponse.data.jobs
                // Fetch the details of each job
                const jobDetailsPromises = jobs.map((job: any) =>
                    axios.get(
                        `https://boards-api.greenhouse.io/v1/boards/sanctu/jobs/${job.id}`
                    )
                )

                // Wait for all the details to be fetched
                const detailsResponses = await Promise.all(jobDetailsPromises)

                // Map the responses to the details
                const details = detailsResponses.map((response) => {
                    const { absolute_url, departments, offices, title } =
                        response.data
                    return {
                        absolute_url,
                        departmentName:
                            departments.length > 0 ? departments[0].name : null,
                        officeName: offices.length > 0 ? offices[0].name : null,
                        title,
                    }
                })

                // Set the state with the jobs details
                setNewDetails(details)

                // Remove duplicate values from the details
                const uniqueTitles = Array.from(
                    new Set(details.map((detail) => detail.title))
                )

                const uniqueDepartments = Array.from(
                    new Set(
                        details
                            .map((detail) => detail.departmentName)
                            .filter(Boolean)
                    )
                )

                const uniqueOffices = Array.from(
                    new Set(
                        details
                            .map((detail) => detail.officeName)
                            .filter(Boolean)
                    )
                )

                // Set the options state with the unique values
                setRoleOptions([
                    {
                        value: i18n.t('BannerJobsBoard.SelectorRoleLabel'),
                        label: i18n.t('BannerJobsBoard.SelectorRoleLabel'),
                    },
                    ...uniqueTitles.map((title) => ({
                        value: title,
                        label: title,
                    })),
                ])

                setTypeOptions([
                    {
                        value: i18n.t('BannerJobsBoard.SelectorTypeLabel'),
                        label: i18n.t('BannerJobsBoard.SelectorTypeLabel'),
                    },
                    ...uniqueDepartments.map((department) => ({
                        value: department,
                        label: department,
                    })),
                ])

                setLocationOptions([
                    {
                        value: i18n.t('BannerJobsBoard.SelectorLocationLabel'),
                        label: i18n.t('BannerJobsBoard.SelectorLocationLabel'),
                    },
                    ...uniqueOffices.map((office) => ({
                        value: office,
                        label: office,
                    })),
                ])
            } catch (error) {
                console.error('Erro ao buscar detalhes das vagas:', error)
            }
        }

        fetchAllJobDetails()
    }, [])

    useEffect(() => {
        // Filter the newDetails based on the selected options
        const updatedCardData = newDetails
            .filter(
                (job) =>
                    (!selectedRole ||
                        job.title === selectedRole ||
                        selectedRole ===
                            i18n.t('BannerJobsBoard.SelectorRoleLabel')) &&
                    (!selectedType ||
                        job.departmentName === selectedType ||
                        selectedType ===
                            i18n.t('BannerJobsBoard.SelectorTypeLabel')) &&
                    (!selectedLocation ||
                        job.officeName === selectedLocation ||
                        selectedLocation ===
                            i18n.t('BannerJobsBoard.SelectorLocationLabel'))
            )
            .map((job) => ({
                title: job.departmentName || 'No Department',
                description: job.title,
                linkUrl: job.absolute_url,
                officeName: job.officeName,
            }))

        // Update the state with the new filtered cardData
        setCardData(updatedCardData)
    }, [selectedRole, selectedType, selectedLocation, newDetails])

    return (
        <div className={styles['jobs-board-container']} id="jobs-board">
            <div className={styles['jobs-board-header']}>
                <Badge text={i18n.t('BannerJobsBoard.BadgeText')} />
                <div className={styles['jobs-board-text']}>
                    <h2>{i18n.t('BannerJobsBoard.Title')}</h2>
                    <p>{i18n.t('BannerJobsBoard.Content')}</p>
                </div>
            </div>
            <div className={styles['jobs-board-select-content']}>
                <div className={styles['jobs-board-select-container']}>
                    <Selector
                        selectorLabel={i18n.t(
                            'BannerJobsBoard.SelectorRoleLabel'
                        )}
                        options={roleOptions}
                        onSelect={(selectedOption) =>
                            setSelectedRole(selectedOption?.value || null)
                        }
                    />
                    <Selector
                        selectorLabel={i18n.t(
                            'BannerJobsBoard.SelectorTypeLabel'
                        )}
                        options={typeOptions}
                        onSelect={(selectedOption) =>
                            setSelectedType(selectedOption?.value || null)
                        }
                    />
                    <Selector
                        selectorLabel={i18n.t(
                            'BannerJobsBoard.SelectorLocationLabel'
                        )}
                        options={locationOptions}
                        onSelect={(selectedOption) =>
                            setSelectedLocation(selectedOption?.value || null)
                        }
                    />
                </div>
                <div className={styles['snap-control-board']}>
                    <p>
                        {cardData.length === 1
                            ? i18n.t('BannerJobsBoard.SelectContent', {
                                  numberOfPositions: cardData.length,
                              })
                            : i18n.t('BannerJobsBoard.SelectContents', {
                                  numberOfPositions: cardData.length,
                              })}
                    </p>
                </div>
                <div className={styles['jobs-board-card-container']}>
                    {cardData.map((card, index) => (
                        <JobCard
                            title={card.title}
                            description={card.description}
                            key={index}
                            linkUrl={card.linkUrl}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default JobsBoard
