import React from 'react'
import styles from './ToggleSwitch.module.css'

// Component used on Slider component
// Example of usage:
/*
<ToggleSwitch
                        isOn={isToggleOn}
                        handleToggle={handleToggle}
                    />
*/

// Lift up the state to the parent component Slider
interface ToggleSwitchProps {
    isOn: boolean
    handleToggle: () => void
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ isOn, handleToggle }) => {
    return (
        <label className={styles['switch-main-container']}>
            <input
                type="checkbox"
                checked={isOn}
                onChange={handleToggle}
                id="toggle"
            />
            <span
                className={`${styles['switch-slider']} ${
                    isOn ? styles['on'] : styles['off']
                }`}
            ></span>
        </label>
    )
}

export default ToggleSwitch
