import styles from './BannerOurPeople.module.css'
import { i18n } from '../../lib/i18n/i18n'
import Badge from '../Badge/Badge'
import CardOurPeople from './CardOurPeople'
import BannerOurPeopleMainPic from '../../assets/images/BannerOurPeopleMainPic.png'
import BannerOurPeopleMainPicMobile from '../../assets/images/BannerOurPeopleMainPic-mobile.jpg'
import useWindowWidth from '../../hooks/useWindowWidth'
import CardOurPeopleIcon1 from '../../assets/images/BannerOurPeopleIcon1.png'
import CardOurPeopleIcon2 from '../../assets/images/BannerOurPeopleIcon2.png'
import CardOurPeopleIcon3 from '../../assets/images/BannerOurPeopleIcon3.png'
import CardOurPeopleIcon4 from '../../assets/images/BannerOurPeopleIcon4.png'
import CardOurPeopleIcon5 from '../../assets/images/BannerOurPeopleIcon5.png'
import React, { useEffect, useRef, useState } from 'react'

const BannerOurPeople = () => {
    const windowWidth = useWindowWidth()
    const ref = useRef(null)
    const [isInView, setIsInView] = useState(false)
    const [fifthCardExpanded, setFifthCardExpanded] = useState(false)

    // Animate the cards last card when it is visible on the screen / bounver 1.2s
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsInView(entry.isIntersecting)
            },
            {
                rootMargin: '-20% 0px -20% 0px',
                threshold: 0.5,
            }
        )

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => observer.disconnect()
    }, [ref])

    useEffect(() => {
        if (isInView && !fifthCardExpanded) {
            const timer = setTimeout(() => {
                setFifthCardExpanded(true)
            }, 1200)

            return () => clearTimeout(timer)
        }
    }, [isInView, fifthCardExpanded])

    return (
        <div className={styles['bannerourpeople-main-container']} ref={ref}>
            {windowWidth <= 1024 && (
                <div className={styles['bannerourpeople-img-container']}>
                    <img
                        src={BannerOurPeopleMainPicMobile}
                        alt="Sanctu people"
                    />
                </div>
            )}
            <div className={styles['bannerourpeople-content-container']}>
                <div className={styles['bannerourpeople-header']}>
                    <Badge text={i18n.t('BannerOurPeople.Badge')} />
                    <div className={styles['bannerourpeople-text']}>
                        <h2>{i18n.t('BannerOurPeople.Title')} </h2>
                        <p>{i18n.t('BannerOurPeople.SubTitle')} </p>
                    </div>
                </div>

                <div className={styles['bannerourpeople-content']}>
                    <div className={styles['bannerourpeople-card-grid']}>
                        <CardOurPeople
                            title={i18n.t('BannerOurPeople.Card1Title')}
                            icon={CardOurPeopleIcon1}
                        />
                        <CardOurPeople
                            title={i18n.t('BannerOurPeople.Card2Title')}
                            icon={CardOurPeopleIcon2}
                        />
                        <CardOurPeople
                            title={i18n.t('BannerOurPeople.Card3Title')}
                            icon={CardOurPeopleIcon3}
                        />
                        <CardOurPeople
                            title={i18n.t('BannerOurPeople.Card4Title')}
                            icon={CardOurPeopleIcon4}
                        />
                        <CardOurPeople
                            title={i18n.t('BannerOurPeople.Card5Title')}
                            icon={CardOurPeopleIcon5}
                            animationProps={
                                fifthCardExpanded
                                    ? {
                                          animate: { scale: 1.2 },
                                          transition: { duration: 0.5 },
                                      }
                                    : { animate: { scale: 1 } }
                            }
                        />
                    </div>
                </div>
            </div>
            {windowWidth > 1024 && (
                <div className={styles['bannerourpeople-img-container']}>
                    <img src={BannerOurPeopleMainPic} alt="Sanctu people" />
                </div>
            )}
        </div>
    )
}

export default BannerOurPeople
