import { i18n } from '../../lib/i18n/i18n'
import styles from './BannerHero.module.css'
import ButtonDefault from '../ButtonDefault/ButtonDefault'
import { motion } from 'framer-motion'
import { useAnimation } from '../../hooks/animationContext'

const BannerHero = () => {
    const { runAnimation, onAnimationComplete } = useAnimation()

    const handleComplete = () => {
        onAnimationComplete()
    }

    const animationConfig = {
        initial: runAnimation ? { y: '-150%', opacity: 0 } : {},
        animate: {
            y: 0,
            opacity: 1,
            // Add transitionEnd to fix the overlap inside nav mobile dropdown menu
            transitionEnd: {
                y: 0,
            },
        },
        transition: { duration: 1 },
        onAnimationComplete: handleComplete,
    }

    return (
        <div className={styles['bannerhero-container']}>
            <div className={styles['bannerhero-content-container']}>
                <motion.div
                    className={styles['bannerhero-content']}
                    {...animationConfig}
                >
                    <h2 className={styles['bannerhero-title']}>
                        {i18n.t('BannerHero.Title')}
                    </h2>
                    <ButtonDefault
                        height={56}
                        text={i18n.t('BannerHero.Button')}
                        to="#reflorestation"
                        width={518}
                    />
                </motion.div>
            </div>
        </div>
    )
}

export default BannerHero
