import styles from './BannerOurGoals.module.css'
import { i18n } from '../../lib/i18n/i18n'
import CardOurGoals1 from '../../assets/images/CardOurGoals1.svg'
import CardOurGoals2 from '../../assets/images/CardOurGoals2.svg'
import CardOurGoals3 from '../../assets/images/CardOurGoals3.svg'
import CardOurGoals from './CardOurGoals'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import useWindowWidth from '../../hooks/useWindowWidth'

const BannerOurGoals = () => {
    const containerRef1 = useRef(null)
    const [renderCard1, setRenderCard1] = useState(false)
    const [renderCard2, setRenderCard2] = useState(false)
    const [renderCard3, setRenderCard3] = useState(false)
    const windowWidth = useWindowWidth()

    // Animate the cards when they are visible on the screen (Intersection Observer)
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.6,
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setRenderCard1(true)
                    observer.disconnect()
                }
            })
        }, options)

        if (containerRef1.current) {
            observer.observe(containerRef1.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [windowWidth])

    return (
        <div
            className={styles['bannerourgoals-main-container']}
            ref={containerRef1}
        >
            <div className={styles['bannerourgoals-title']}>
                <h2>{i18n.t('BannerOurGoals.Title')}</h2>
            </div>
            <div className={styles['bannerourgoals-card-grid']}>
                <AnimatePresence>
                    <motion.div
                        className={styles['bannerourgoals-card']}
                        key={1}
                        initial={{ opacity: 0, x: '1000px' }}
                        animate={renderCard1 ? { opacity: 1, x: 0 } : {}}
                        transition={{ duration: 0.8 }}
                        onAnimationComplete={() => {
                            setRenderCard2(true)
                        }}
                    >
                        <CardOurGoals
                            img={CardOurGoals1}
                            title={i18n.t('BannerOurGoals.TitleCard1')}
                            content={i18n.t('BannerOurGoals.ContentCard1')}
                        />
                    </motion.div>
                    <motion.div
                        className={styles['bannerourgoals-card']}
                        key={2}
                        initial={{ opacity: 0, x: '1000px' }}
                        animate={renderCard2 ? { opacity: 1, x: 0 } : {}}
                        transition={{ duration: 0.8 }}
                        onAnimationComplete={() => {
                            setRenderCard3(true)
                        }}
                    >
                        <CardOurGoals
                            img={CardOurGoals2}
                            title={i18n.t('BannerOurGoals.TitleCard2')}
                            content={i18n.t('BannerOurGoals.ContentCard2')}
                        />
                    </motion.div>
                    <motion.div
                        className={styles['bannerourgoals-card']}
                        key={3}
                        initial={{ opacity: 0, x: '1000px' }}
                        animate={renderCard3 ? { opacity: 1, x: 0 } : {}}
                        transition={{ duration: 0.8 }}
                        onAnimationComplete={() => {
                            setRenderCard1(true)
                        }}
                    >
                        <CardOurGoals
                            img={CardOurGoals3}
                            title={i18n.t('BannerOurGoals.TitleCard3')}
                            content={i18n.t('BannerOurGoals.ContentCard3')}
                        />
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    )
}

export default BannerOurGoals
