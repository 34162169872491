import styles from './BannerCarbonCreditScore.module.css'
import Badge from '../Badge/Badge'
import { i18n } from '../../lib/i18n/i18n'
import CardCarbonCreditScore from './CardCarbonCreditScore'
import ArrowRight from '../../assets/images/BannerCarbonCreditScoreArrowRight.svg'
import ArrowLeft from '../../assets/images/BannerCarbonCreditScoreArrowLeft.svg'
import useHorizontalScroll from '../../hooks/useHorizontalScroll'
import useWindowWidth from '../../hooks/useWindowWidth'

const BannerCarbonCreditScore = () => {
    const cardsData = [
        { squareIndex: 6 },
        { squareIndex: 6 },
        { squareIndex: 6 },
        { squareIndex: 6 },
        { squareIndex: 6 },
    ]

    // Importing the useHorizontalScroll hook
    const {
        containerRef,
        handleScrollLeft,
        handleScrollRight,
        leftOpacity,
        rightOpacity,
    } = useHorizontalScroll()

    const currentLanguage = i18n.language
    const windowWidth = useWindowWidth()
    const isPortugueseAndSmallScreen =
        currentLanguage === 'pt' && windowWidth < 450

    const headerStyle = isPortugueseAndSmallScreen
        ? {
              fontSize: '28px',
              lineHeight: '30px',
          }
        : {}

    const paragraphStyle = isPortugueseAndSmallScreen
        ? {
              fontSize: '18px',
              lineHeight: '20px',
          }
        : {}

    return (
        <div className={styles['bannercarboncreditscore-main-container']}>
            <div className={styles['bannercarboncreditscore-header-container']}>
                <Badge text={i18n.t('BannerCarbonCreditScore.Badge')} />
                <div
                    className={styles['bannercarboncreditscore-text-container']}
                >
                    <h2 style={headerStyle}>
                        {i18n.t('BannerCarbonCreditScore.Title')}
                    </h2>
                    <p style={paragraphStyle}>
                        {i18n.t('BannerCarbonCreditScore.Content')}
                    </p>
                </div>
            </div>
            <div
                className={styles['bannercarboncreditscore-cards-grid']}
                ref={containerRef}
            >
                {cardsData.map((data, index) => (
                    <CardCarbonCreditScore
                        key={index}
                        badgeText={`0${index + 1}`}
                        titleText={i18n.t(
                            `BannerCarbonCreditScore.TitleCard${index + 1}`
                        )}
                        contentText={i18n.t(
                            `BannerCarbonCreditScore.ContentCard${index + 1}`
                        )}
                        squareIndex={data.squareIndex}
                    />
                ))}
            </div>
            <div className={styles['bannercarboncredits-buttonscroll']}>
                <img
                    src={ArrowLeft}
                    alt="Seta para esquerda"
                    onClick={handleScrollLeft}
                    style={{ opacity: leftOpacity }}
                />
                <img
                    src={ArrowRight}
                    alt="Seta para direita"
                    onClick={handleScrollRight}
                    style={{ opacity: rightOpacity }}
                />
            </div>
        </div>
    )
}

export default BannerCarbonCreditScore
