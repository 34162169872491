import Footer from '../../components/Footer/Footer'
import Nav from '../../components/Nav/Nav'
import BannerCookiesPolicies from '../../components/BannerCookiesPolicies/BannerCookiesPolicies'
import BannerNoBackground from '../../components/BannerNoBackground/BannerNoBackground'
import styles from './Cookies.module.css'
import { i18n } from '../../lib/i18n/i18n'
import { useEffect, useState } from 'react'

const ContactUs = () => {
    const [android, setAndroid] = useState(false)
    useEffect(() => {
        const isAndroid = /Android/i.test(navigator.userAgent)
        if (isAndroid) {
            setAndroid(true)
        } else {
            setAndroid(false)
        }
    }, [])
    return (
        <div
            className={
                android
                    ? styles['snap-control-css-android']
                    : styles['snap-control-css']
            }
        >
            <div className={styles['snap-section-navbanner']}>
                <Nav />
                <BannerNoBackground title={i18n.t('BannerCookies.Title')} />
            </div>
            <div className={styles['container']}>
                <div className={styles['snap-section-policiestext']}>
                    <BannerCookiesPolicies />
                </div>
            </div>
            <div className={styles['snap-section-footer']}>
                <Footer />
            </div>
        </div>
    )
}

export default ContactUs
