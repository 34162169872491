import styles from './BannerNoBackground.module.css'

interface BannerNoBackgroundProps {
    title: string
}

const BannerNoBackground: React.FC<BannerNoBackgroundProps> = ({ title }) => {
    return (
        <div className={styles['banner-header']}>
            <h2>{title}</h2>
        </div>
    )
}

export default BannerNoBackground
