import styles from './ErrorMessage.module.css'

interface ErrorMessageProps {
    text: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ text }) => {
    return (
        <div className={styles['error-msg-container']}>
            <p>{text}</p>
        </div>
    )
}

export default ErrorMessage
