import Banner404 from '../../components/Banner404/Banner404'
import Footer from '../../components/Footer/Footer'
import Nav from '../../components/Nav/Nav'

const Page404 = () => {
    return (
        <>
            <Nav />
            <Banner404 />
            <Footer />
        </>
    )
}

export default Page404
