import styles from './InputReforestation.module.css'
import React from 'react'

interface InputReforestationProps {
    text: string
    placeholder: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const InputReforestation: React.FC<
    InputReforestationProps & React.RefAttributes<HTMLInputElement>
> = React.forwardRef<HTMLInputElement, InputReforestationProps>(
    ({ text, placeholder, onChange }, ref) => {
        const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
            e.currentTarget.blur()
        }

        return (
            <div className={styles['card-question-input']}>
                <p>{text}</p>
                <div className={styles['input-container']}>
                    <input
                        ref={ref}
                        type="number"
                        onWheel={handleWheel}
                        onChange={onChange}
                        placeholder={placeholder}
                        id="input-reforestation"
                    />
                    <span className={styles['fixed-suffix']}>Ha</span>
                </div>
            </div>
        )
    }
)

export default InputReforestation
