import ReactDOM from 'react-dom/client'
import './index.css'
import App from './app/App'
import reportWebVitals from '../src/lib/reactdefault/reportWebVitals'
import './lib/i18n/i18n'
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import '../src/assets/fonts/Rubik-Regular.ttf'
import '../src/assets/fonts/Rubik-Bold.ttf'
import * as smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<App />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
)

reportWebVitals()
