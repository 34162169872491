import { Outlet } from 'react-router-dom'

// Defining a functional component named Layout
const Layout = () => {
    // Rendering the component
    return (
        // Main element wrapping the entire layout, it has a class name of "App"
        <main className="App">
            {/* Outlet component is used here; it serves as a placeholder
            where the routed component will be displayed */}
            <Outlet />
        </main>
    )
}

export default Layout
