import { i18n } from '../../lib/i18n/i18n'
import styles from './BannerOneOfUs.module.css'
import ButtonDefault from '../ButtonDefault/ButtonDefault'

const BannerOneOfUs = () => {
    return (
        <div className={styles['banner-one-of-us-container']}>
            <div className={styles['banner-one-of-us-content']}>
                <h2>{i18n.t('BannerOneOfUs.Title')}</h2>
                <p>{i18n.t('BannerOneOfUs.Content')}</p>
            </div>
            <ButtonDefault
                height={56}
                text={i18n.t('BannerOneOfUs.Button')}
                to="/joinus"
            />
        </div>
    )
}

export default BannerOneOfUs
