import styles from './CardOurTech.module.css'
import ArrowRight from '../../assets/images/CardOurTechArrowRight.svg'
import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'

// Component used on BannerOurTech

interface CardOurTechProps {
    index: string | number
    title: string
    content1?: string
    content2: string
    img: string
}

const CardOurTech: React.FC<CardOurTechProps> = ({
    index,
    title,
    content1,
    content2,
    img,
}) => {
    const [isVisible, setIsVisible] = useState(false)
    const containerRef = useRef(null)
    const windowWidth = window.innerWidth

    // Animation for the cards
    useEffect(() => {
        const options = {
            root: null,
            threshold: 1,
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (index === 1) {
                        setIsVisible(true)
                    } else {
                        setIsVisible(true)
                    }
                } else {
                    setIsVisible(false)
                }
            })
        }, options)

        if (containerRef.current) {
            observer.observe(containerRef.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [containerRef, setIsVisible, index])

    return (
        <div ref={containerRef} className={styles['snap-section-center']}>
            <motion.div
                className={styles['cardourtech-container']}
                animate={{ scale: isVisible ? 1.06 : 1 }}
                transition={{ duration: 0.8, ease: 'easeInOut' }}
            >
                {windowWidth < 500 ? (
                    <>
                        <div className={styles['cardourtech-text-content']}>
                            <div
                                className={styles['cardourtech-img-container']}
                            >
                                <img src={img} alt="Card pic" />
                            </div>
                            <div
                                className={
                                    styles[
                                        'cardourtech-header-mobile-container'
                                    ]
                                }
                            >
                                <h3>{title}</h3>
                                <h2>{index}</h2>
                            </div>
                            <div className={styles['cardourtech-content']}>
                                {content1 && (
                                    <div
                                        className={
                                            styles[
                                                'cardourtech-arrow-container'
                                            ]
                                        }
                                    >
                                        <img
                                            src={ArrowRight}
                                            alt="Arrow right"
                                        />
                                        <p>{content1}</p>
                                    </div>
                                )}
                                <div
                                    className={
                                        styles['cardourtech-arrow-container']
                                    }
                                >
                                    <img src={ArrowRight} alt="Arrow right" />
                                    <p>{content2}</p>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles['cardourtech-text-content']}>
                            <div
                                className={
                                    styles['cardourtech-header-container']
                                }
                            >
                                <h3>{title}</h3>
                                <h2>{index}</h2>
                            </div>
                            <div className={styles['cardourtech-content']}>
                                {content1 && (
                                    <div
                                        className={
                                            styles[
                                                'cardourtech-arrow-container'
                                            ]
                                        }
                                    >
                                        <img
                                            src={ArrowRight}
                                            alt="Arrow right"
                                        />
                                        <p>{content1}</p>
                                    </div>
                                )}
                                <div
                                    className={
                                        styles['cardourtech-arrow-container']
                                    }
                                >
                                    <img src={ArrowRight} alt="Arrow right" />
                                    <p>{content2}</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles['cardourtech-img-container']}>
                            <img src={img} alt="Card pic" />
                        </div>
                    </>
                )}
            </motion.div>
        </div>
    )
}

export default CardOurTech
