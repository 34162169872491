import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        supportedLngs: ['en', 'pt'], // List of supported languages
        nonExplicitSupportedLngs: true,
        load: 'languageOnly',
        detection: {
            // order and from where user language should be detected
            order: [
                'querystring',
                'cookie',
                'localStorage',
                'sessionStorage',
                'navigator',
                'htmlTag',
                'path',
                'subdomain',
            ],

            // keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupSessionStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // cache user language on
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            // optional htmlTag with lang attribute, the default is:
            htmlTag: document.documentElement,
        },

        backend: {},
    })

export { i18n }
