import styles from './BannerVideo.module.css'
import { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import useWindowWidth from '../../hooks/useWindowWidth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons'
import video_image from '../../assets/images/BannerVideo_image.jpg'
import Badge from '../Badge/Badge'
import { i18n } from '../../lib/i18n/i18n'
import ReactGA from 'react-ga4'
import ButtonNoBackground from '../ButtonNoBackground/ButtonNoBackground'

const BannerVideo = () => {
    const [play, setPlay] = useState(false)
    const playerRef = useRef(null)
    const windowWidth = useWindowWidth()
    const [hovered, setHovered] = useState(false)
    let timeoutId: ReturnType<typeof setTimeout> | null = null
    const [videoURL, setVideoURL] = useState('')

    // DNS path for videos based on user's language and viewport size
    // 1000px is the breakpoint for mobile and desktop
    useEffect(() => {
        const baseVideoURL = 'https://sanctuvideolanding.azureedge.net/'
        const language = i18n.language === 'pt' ? 'PT' : 'EN'
        const videoVersion = windowWidth < 1000 ? '-mobile' : ''
        setVideoURL(
            `${baseVideoURL}sanctumanifestoversion${language}${videoVersion}.mp4`
        )
    }, [windowWidth])

    // Detect when the video is visible on the screen if the user is on a big screen
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0]
                if (entry.isIntersecting) {
                    setPlay(windowWidth > 450)
                }
            },
            { threshold: 0.5 }
        )

        if (playerRef.current) {
            observer.observe(playerRef.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [windowWidth])

    // Detect when the user hovers the video
    const handleMouseMove = () => {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }

        let timeout = 3000
        setHovered(true)

        timeoutId = setTimeout(() => {
            setHovered(false)
        }, timeout)
    }

    // Detect when the user stops hovering the video
    const handleMouseOut = () => {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
        setHovered(false)
    }

    // Function to handle the play button
    const handlePlay = () => {
        setPlay(true)
    }

    // Function to handle GA event when video ends
    const handleVideoEnd = () => {
        ReactGA.event({
            category: 'Video',
            action: 'WatchedFullVideo',
            label: 'Banner Video',
        })
    }

    return (
        <div className={styles['banner-video-container']} ref={playerRef}>
            {windowWidth < 450 ? (
                <Badge text={i18n.t('BannerVideo.Badge')} />
            ) : null}

            <div
                className={styles['banner-video-content']}
                onMouseMove={handleMouseMove}
                onMouseOut={handleMouseOut}
                style={hovered ? { backgroundColor: 'black' } : {}}
            >
                {hovered && windowWidth > 450 ? (
                    <FontAwesomeIcon
                        className={styles['banner-video-icon']}
                        icon={play ? faPause : faPlay}
                        style={{ color: '#ffffff' }}
                        onClick={() => setPlay(play ? false : true)}
                    />
                ) : null}
                <ReactPlayer
                    style={
                        hovered
                            ? { opacity: 0.6, backgroundColor: 'black' }
                            : {}
                    }
                    url={videoURL}
                    width="100%"
                    onMouseMove={handleMouseMove}
                    onMouseOut={handleMouseOut}
                    className={styles['banner-video-player']}
                    light={windowWidth < 450 ? video_image : false}
                    height={windowWidth < 450 ? '30vh' : '100%'}
                    playing={play}
                    onClickPreview={() => setPlay(true)}
                    muted={true}
                    controls={true}
                    onPause={() => setPlay(false)}
                    onPlay={handlePlay}
                    onEnded={handleVideoEnd}
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload',
                                preload: 'auto',
                            },
                        },
                    }}
                />
            </div>
            {windowWidth < 450 && (
                <ButtonNoBackground
                    text={i18n.t('BannerOurServices.Button')}
                    to={'/contactus'}
                />
            )}
        </div>
    )
}

export default BannerVideo
