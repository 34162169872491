import React, { useState } from 'react'
import styles from './ButtonGuardian.module.css'
import { ModalGuardian } from '../ModalGuardian/ModalGuardian'
import { i18n } from '../../lib/i18n/i18n'
import Sanctu_logo_green from '../../assets/images/ButtonGuardianSanctuLogo.svg'

// Component used on BannerReforestation

interface ButtonGuardianProps {
    reforestationArea: number
    totalHectares: number
    onClick: () => void
    disabled: boolean
    userInputChanged?: boolean
}

const ButtonGuardian: React.FC<ButtonGuardianProps> = ({
    reforestationArea,
    totalHectares,
    onClick,
    disabled,
    userInputChanged,
}) => {
    // State to control modal visibility
    const [showModal, setShowModal] = useState(false)

    // Function to close the modal
    const handleCloseModal = () => {
        setShowModal(false)
        document.body.classList.remove('hide-scroll-bar')
    }

    // Function to handle the submission of the form inside the modal
    const handleModalSubmit = (data: any) => {
        setShowModal(false)
    }

    // Function to allow user goes to modal if there is a valid inputValue
    const handleClick = () => {
        if (disabled) {
            return
        }
        onClick()
        document.body.classList.add('hide-scroll-bar')
        if (userInputChanged) {
            setShowModal(true)
        }
    }

    return (
        <>
            <button
                className={styles['button-guardian']}
                onClick={handleClick}
                disabled={disabled}
            >
                <img src={Sanctu_logo_green} alt="Sanctu logo" />
                <p>{i18n.t('BannerReforestation.Button2')}</p>
            </button>
            {/* Conditionally render the Modal component based on the showModal state */}
            <ModalGuardian
                show={showModal}
                onClose={handleCloseModal}
                onSubmit={handleModalSubmit}
                totalHectares={totalHectares}
                reforestationArea={reforestationArea}
            />
        </>
    )
}

export default ButtonGuardian
