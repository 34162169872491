import styles from './BannerAnsweredQuestions.module.css'
import Badge from '../Badge/Badge'
import CardFaq from './CardFaq'

interface Question {
    question: string
    answer: string
}

interface BannerAnsweredQuestionsProps {
    badgeText: string
    badgeBackgroundColor: string
    bannerBackgroundColor?: string
    bannerTitle: string
    bannerText?: string
    questions: Question[]
}

const BannerAnsweredQuestions: React.FC<BannerAnsweredQuestionsProps> = ({
    badgeText,
    badgeBackgroundColor,
    bannerBackgroundColor,
    bannerTitle,
    bannerText,
    questions,
}) => {
    return (
        <div className={styles['questions-container']}>
            <div
                className={styles['questions-content']}
                style={{
                    backgroundColor: bannerBackgroundColor,
                }}
            >
                <div className={styles['questions-header-container']}>
                    <Badge
                        text={badgeText}
                        backgroundColor={badgeBackgroundColor}
                    />
                    <h2>{bannerTitle}</h2>
                    {bannerText}
                </div>
                <div className={styles['questions-card-grid']}>
                    {questions.map(({ question, answer }, index) => (
                        <CardFaq
                            question={question}
                            answer={answer}
                            key={index + 1}
                            // Style to apply border radius to the first and last card
                            style={{
                                borderTopLeftRadius: index === 0 ? '8px' : '0',
                                borderTopRightRadius: index === 0 ? '8px' : '0',
                                borderBottomLeftRadius:
                                    index === questions.length - 1
                                        ? '8px'
                                        : '0',
                                borderBottomRightRadius:
                                    index === questions.length - 1
                                        ? '8px'
                                        : '0',
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BannerAnsweredQuestions
