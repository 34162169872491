import styles from './CardServices.module.css'

// Component used on BannerOurServices

interface CardServicesProps {
    number: string
    text: string
    image: string
    customStyle?: string
}

const CardServices: React.FC<CardServicesProps> = ({
    number,
    text,
    image,
    customStyle,
}) => {
    return (
        <div className={styles['cardservices-main-container']}>
            <div className={styles['cardservices-header-container']}>
                <div className={styles['cardservices-header-top']}>
                    <h1>{number}</h1>
                </div>
                <p>{text}</p>
            </div>
            <div className={styles['cardservices-image-container']}>
                <img src={image} alt="Card pic" />
            </div>
        </div>
    )
}

export default CardServices
