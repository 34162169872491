import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSquareInstagram,
    faLinkedin,
    faSquareFacebook,
    faSquareXTwitter,
} from '@fortawesome/free-brands-svg-icons'
import Badge from '../Badge/Badge'
import ButtonWhatsApp from '../ButtonWhatsApp/ButtonWhatsApp'
import styles from './BannerGetInTouch.module.css'
import InputField from '../InputField/InputField'
import { useEffect, useState } from 'react'
import validateField from '../../hooks/validateForm'
import ButtonDefaultDark from '../ButtonDefaultDark/ButtonDefaultDark'
import { i18n } from '../../lib/i18n/i18n'
import { Link } from 'react-router-dom'
import axios from '../../lib/api/axios'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import '../ModalGuardian/PhoneStyle.css'
import { useNavigate } from 'react-router-dom'
import useWindowWidth from '../../hooks/useWindowWidth'
import { ThreeDots } from 'react-loader-spinner'

// Define the properties expected by the InputField component
interface InputValues {
    first_name: string
    last_name: string
    email: string
    phone_number: string
    subject: string
    message_content: string
}

// Define interface to control the input field validity
interface ValidFields {
    first_name: boolean
    last_name: boolean
    email: boolean
    phone_number: boolean
    subject: boolean
    message_content: boolean
}

// API endpoints
const CONTACTS = 'contacts/'

const BannerGetInTouch = () => {
    const [errorMessage, setErrorMessage] = useState(false)
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const witdh = useWindowWidth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [validate, setValidate] = useState(false)

    // Const for the icons
    const icons = [
        {
            icon: faSquareFacebook,
            to: '/',
        },
        {
            icon: faSquareInstagram,
            to: '/',
        },
        {
            icon: faLinkedin,
            to: '/',
        },
        {
            icon: faSquareXTwitter,
            to: '/',
        },
    ]

    // Default State for input values
    const [inputValues, setInputValues] = useState<InputValues>({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        subject: '',
        message_content: '',
    })

    // Default State to control the input field validity
    const [validFields, setValidFields] = useState<ValidFields>({
        first_name: true,
        last_name: true,
        email: true,
        phone_number: true,
        subject: true,
        message_content: true,
    })

    // Array with the field names to check for validity
    const fieldNamesToCheck: Array<keyof ValidFields> = [
        'first_name',
        'last_name',
        'email',
        'phone_number',
        'subject',
        'message_content',
    ]

    const [phoneFocus, setPhoneFocus] = useState(false)
    const handleInputChange = (
        fieldName: string,
        value: string | undefined
    ) => {
        // Hide the error message when the user starts typing
        setErrorMessage(false)
        if (value !== undefined) {
            // Validate the field from validateForm.ts
            const isValid = validateField(fieldName, value)

            // Update the state with the validity of the input field
            setValidFields((prevValidFields) => ({
                ...prevValidFields,
                [fieldName]: isValid,
            }))

            // Update the state with the new value
            setInputValues((prevInputValues) => ({
                ...prevInputValues,
                [fieldName]: value,
            }))
        }
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setLoading(true)
        setValidate(true)

        // New object to store updated valid fields
        const updatedValidFields: ValidFields = { ...validFields }

        // If the user leave a field empty, the field will be marked as invalid
        fieldNamesToCheck.forEach((fieldName) => {
            if (inputValues[fieldName] === '') {
                updatedValidFields[fieldName] = false
            }
        })

        // Update the validFields state with the updated values
        setValidFields(updatedValidFields)

        // Check if all fields are valid
        const allFieldsValid = Object.values(updatedValidFields).every(
            (isValid) => isValid
        )

        // If any field is invalid, don't submit the form
        if (!allFieldsValid) {
            setErrorMessage(true)
            setLoading(false)
            return
        }

        // Using form data to create an object
        const formData = new FormData(e.target as HTMLFormElement)
        const contactData = Object.fromEntries(formData)
        contactData.phone_number = inputValues.phone_number

        // If the language is english, set portuguese_speaker to false
        if (i18n.language.includes('en')) {
            contactData['portuguese_speaker'] = 'false'
        }

        try {
            await axios.post(CONTACTS, JSON.stringify(contactData), {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            navigate('/success')
        } catch (err: any) {
            if (err.response) {
                switch (err.response.status) {
                    case 404:
                        navigate('/404')
                        break
                    case 500:
                        navigate('/500')
                        break
                    default:
                        navigate('/404')
                }
            } else if (err.request) {
                // The request was made, but no response was received
                navigate('/500')
            } else {
                // Something else happened while setting up the request
                navigate('/404')
            }
        }
        setLoading(false)
    }

    const handleFocus = () => {
        setIsFocused(true)
    }

    useEffect(() => {
        if (errorMessage && witdh < 1024) {
            // Scroll to the top of the page
            const topElement = document.getElementById('get-touch-button')
            if (topElement) {
                topElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
            }
        }
    }, [errorMessage, witdh])

    return (
        <div className={styles['get-touch-container']} id="get-touch">
            <div className={styles['get-touch-left-container']}>
                <div className={styles['get-touch-left-content']}>
                    <Badge
                        text={i18n.t('BannerGetInTouch.Badge')}
                        backgroundColor={'var(--neutral-color)'}
                    />
                    <div className={styles['get-touch-left-text']}>
                        <h2>{i18n.t('BannerGetInTouch.Title')}</h2>
                        <p>{i18n.t('BannerGetInTouch.Content')}</p>
                    </div>
                    <div id="get-touch-button">
                        <ButtonWhatsApp
                            text={i18n.t('BannerGetInTouch.ButtonWhatsApp')}
                            whatsappNumber={'+5511942483414'}
                        />
                    </div>
                </div>
                <div className={styles['links-container']}>
                    <p>{i18n.t('BannerGetInTouch.IconsLabel')}</p>
                    <div className={styles['icons-container']}>
                        {icons.map((icon, index) => (
                            <Link to={icon.to} key={index}>
                                <FontAwesomeIcon icon={icon.icon} />
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <form
                className={styles['get-touch-form-container']}
                onSubmit={handleSubmit}
            >
                {errorMessage ? (
                    <ErrorMessage text={i18n.t('BannerGetInTouch.ErrorMsg')} />
                ) : null}
                <div className={styles['snap-control']}>
                    <div className={styles['get-touch-form-up']}>
                        <div className={styles['get-touch-input-container']}>
                            <InputField
                                name="first_name"
                                label={i18n.t('BannerGetInTouch.FirstName')}
                                value={inputValues.first_name}
                                valid={validate ? validFields.first_name : true}
                                onInputChange={handleInputChange}
                            />
                            <InputField
                                name="last_name"
                                label={i18n.t('BannerGetInTouch.LastName')}
                                value={inputValues.last_name}
                                valid={validate ? validFields.last_name : true}
                                onInputChange={handleInputChange}
                            />
                        </div>
                        <InputField
                            name="email"
                            label={i18n.t('BannerGetInTouch.EmailAddress')}
                            value={inputValues.email}
                            valid={validate ? validFields.email : true}
                            onInputChange={handleInputChange}
                        />
                        <div
                            className={
                                styles['get-touch-phone-input-container']
                            }
                        >
                            <p className={styles['input-label']}>
                                {i18n.t('BannerGetInTouch.PhoneNumber')}
                            </p>
                            <PhoneInput
                                country={'br'}
                                value={inputValues.phone_number}
                                onChange={(value) =>
                                    handleInputChange('phone_number', value)
                                }
                                containerStyle={{
                                    border: phoneFocus
                                        ? '2px solid var(--dark-color)'
                                        : (validate &&
                                              !validFields.phone_number &&
                                              '2px solid #bc4337') ||
                                          '1px solid var(--dark-color)',
                                }}
                                onFocus={() => {
                                    setPhoneFocus(true)
                                }}
                                onBlur={() => {
                                    setPhoneFocus(false)
                                }}
                                enableSearch={true}
                                containerClass={
                                    styles['get-touch-phone-container']
                                }
                                inputClass={styles['get-touch-phone-input']}
                                buttonClass={styles['get-touch-phone-button']}
                                dropdownClass={
                                    styles['get-touch-phone-dropdown']
                                }
                                searchClass={styles['get-touch-phone-search']}
                                disableSearchIcon={true}
                                searchPlaceholder="Search for a country"
                                jumpCursorToEnd={true}
                                autocompleteSearch={true}
                                searchNotFound={'searchFind'}
                            />
                        </div>
                    </div>
                </div>
                <InputField
                    name="subject"
                    label={i18n.t('BannerGetInTouch.Subject')}
                    value={inputValues.subject}
                    valid={validate ? validFields.subject : true}
                    onInputChange={handleInputChange}
                />
                <div className={styles['snap-control-msg']}>
                    <div className={styles['get-touch-textarea-container']}>
                        <p>{i18n.t('BannerGetInTouch.YourMessage')}</p>
                        <textarea
                            name="message_content"
                            placeholder={i18n.t('BannerGetInTouch.WriteHere')}
                            rows={witdh < 1440 ? 4 : 8}
                            value={inputValues.message_content}
                            onChange={(e) =>
                                handleInputChange(
                                    'message_content',
                                    e.target.value
                                )
                            }
                            style={{
                                border:
                                    (validate &&
                                        (!validFields.message_content
                                            ? '2px solid #bc4337'
                                            : isFocused
                                            ? '2px solid var(--dark-color)'
                                            : '1px solid var(--dark-color)')) ||
                                    '1px solid var(--dark-color)',
                            }}
                            onFocus={handleFocus}
                            onBlur={() => setIsFocused(false)}
                        ></textarea>
                    </div>
                    <div className={styles['get-touch-submit']}>
                        <ButtonDefaultDark
                            type={'submit'}
                            width={'100%'}
                            height={56}
                            text={i18n.t('BannerGetInTouch.SubmitButton')}
                        />
                    </div>
                    {loading && (
                        <div className={styles['get-touch-loading-spinner']}>
                            <ThreeDots
                                visible={true}
                                height="80"
                                width="80"
                                color="var(--pine-color)"
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>
                    )}
                </div>
            </form>
        </div>
    )
}

export default BannerGetInTouch
