import { i18n } from '../../lib/i18n/i18n'
import CardFaq from '../BannerAnsweredQuestions/CardFaq'
import styles from './BannerFaqs.module.css'

const BannerFaqs = () => {
    const questions = [
        {
            question: i18n.t('BannerFaqs.Question1'),
            answer: i18n.t('BannerFaqs.Answer1'),
        },
        {
            question: i18n.t('BannerFaqs.Question2'),
            answer: i18n.t('BannerFaqs.Answer2'),
        },
        {
            question: i18n.t('BannerFaqs.Question3'),
            answer: i18n.t('BannerFaqs.Answer3'),
        },
    ]

    return (
        <div className={styles['banner-faqs-container']}>
            <div className={styles['banner-faqs-content']}>
                <div className={styles['banner-faqs-card-container']}>
                    {/* 
                       Iterating through the questions list and rendering 
                       each question and answer as a CardFaq component adding
                       Border Radius to the first and last card
                    */}

                    {questions.map(({ question, answer }, index) => (
                        <CardFaq
                            question={question}
                            answer={answer}
                            key={index + 1}
                            style={{
                                borderTopLeftRadius: index === 0 ? '8px' : '0',
                                borderTopRightRadius: index === 0 ? '8px' : '0',
                                borderBottomLeftRadius:
                                    index === questions.length - 1
                                        ? '8px'
                                        : '0',
                                borderBottomRightRadius:
                                    index === questions.length - 1
                                        ? '8px'
                                        : '0',
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BannerFaqs
