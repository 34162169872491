import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareWhatsapp } from '@fortawesome/free-brands-svg-icons'
import styles from './ButtonWhatsApp.module.css'

interface ButtonWhatsAppProps {
    text: string
    backgroundColor?: string
    whatsappNumber?: string
}

const ButtonWhatsApp: React.FC<ButtonWhatsAppProps> = ({
    text,
    backgroundColor,
    whatsappNumber,
}) => {
    // Function to open WhatsApp
    const openWhatsApp = () => {
        if (whatsappNumber) {
            const whatsappUrl = `https://wa.me/${whatsappNumber.replace(
                /\+/g,
                ''
            )}`
            window.open(whatsappUrl, '_blank')
        }
    }

    return (
        <button
            className={styles['button-whatsapp-container']}
            style={{ backgroundColor: backgroundColor }}
            onClick={openWhatsApp}
        >
            {text}
            <FontAwesomeIcon
                icon={faSquareWhatsapp}
                className={styles['whatsapp-icon']}
            />
        </button>
    )
}

export default ButtonWhatsApp
