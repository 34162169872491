import React, { useState } from 'react'
import Select from 'react-select'
import './Selector.css'

interface SelectorProps {
    selectorLabel: string
    options: {
        value: string
        label: string
    }[]
    onSelect: (selectedOption: { value: string; label: string } | null) => void
}

const Selector: React.FC<SelectorProps> = ({
    selectorLabel,
    options,
    onSelect,
}) => {
    const [selectedOption, setSelectedOption] = useState<{
        value: string
        label: string
    } | null>(null)

    const handleOptionSelect = (
        selectedOption: { value: string; label: string } | null
    ) => {
        setSelectedOption(selectedOption)
        onSelect(selectedOption)
    }

    return (
        <div className="selector-container">
            <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={{
                    dropdownIndicator: (base) => ({ ...base, display: 'none' }),
                }}
                unstyled={true}
                options={options}
                placeholder={selectorLabel}
                value={selectedOption}
                isSearchable={false}
                onChange={(selectedOption) =>
                    handleOptionSelect(selectedOption)
                }
            />
        </div>
    )
}

export default Selector
