import { i18n } from '../../lib/i18n/i18n'
import styles from './BannerBuyCredits.module.css'
import ButtonDefaultDark from '../ButtonDefaultDark/ButtonDefaultDark'
import BannerBuyCreditsMainPic from '../../assets/images/BannerBuyCreditsMainPic.jpg'
import BannerBuyCreditsMainPicMobile from '../../assets/images/BannerBuyCreditsMainPic-mobile.jpg'
import useWindowWidth from '../../hooks/useWindowWidth'
import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'

const BannerBuyCredits = () => {
    const windowWidth = useWindowWidth()
    const [isVisible, setIsVisible] = useState(false)
    const bannerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.6,
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true)
                    observer.disconnect()
                }
            })
        }, options)

        if (bannerRef.current) {
            observer.observe(bannerRef.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [])

    return (
        <div className={styles['banner-credits-main-container']}>
            <div className={styles['banner-credits-container']}>
                <div className={styles['banner-credits-left-container']}>
                    {i18n.language.toLowerCase().includes('en') ? (
                        <div
                            className={styles['banner-credits-left-content']}
                            ref={bannerRef}
                        >
                            <div className={styles['banner-credits-text']}>
                                <h1>{i18n.t('BannerBuyCredits.Title')}</h1>
                                <motion.h1
                                    style={{
                                        transformOrigin: 'center left',
                                        marginLeft: '2px',
                                    }}
                                    initial={{ scale: 1, opacity: 0 }}
                                    animate={
                                        isVisible
                                            ? {
                                                  scale: 1.2,
                                                  opacity: 1,
                                                  y: '-5%',
                                              }
                                            : { scale: 1, opacity: 0 }
                                    }
                                    transition={{ duration: 0.8, delay: 1.2 }}
                                >
                                    +
                                </motion.h1>
                                <motion.h1
                                    style={{
                                        transformOrigin: 'center left',
                                        marginLeft: '2px',
                                    }}
                                    initial={{ scale: 1, opacity: 0 }}
                                    animate={
                                        isVisible
                                            ? {
                                                  scale: 1.2,
                                                  opacity: 1,
                                                  y: '-5%',
                                              }
                                            : { scale: 1, opacity: 0 }
                                    }
                                    transition={{ duration: 0.8, delay: 2.0 }}
                                >
                                    +
                                </motion.h1>
                                <motion.h1
                                    style={{
                                        transformOrigin: 'center left',
                                        marginLeft: '2px',
                                    }}
                                    initial={{ scale: 1, opacity: 0 }}
                                    animate={
                                        isVisible
                                            ? {
                                                  scale: 1.2,
                                                  opacity: 1,
                                                  y: '-5%',
                                              }
                                            : { scale: 1, opacity: 0 }
                                    }
                                    transition={{ duration: 0.8, delay: 2.8 }}
                                >
                                    +
                                </motion.h1>
                            </div>

                            <h1>{i18n.t('BannerBuyCredits.Title2')}</h1>
                            <p>{i18n.t('BannerBuyCredits.Content')}</p>
                        </div>
                    ) : (
                        <div
                            className={styles['banner-credits-left-content']}
                            ref={bannerRef}
                        >
                            <div className={styles['banner-credits-text-pt']}>
                                <h1>{i18n.t('BannerBuyCredits.Title')}</h1>
                                <div
                                    className={styles['banner-credits-text-h1']}
                                >
                                    A
                                    <motion.h1
                                        style={{
                                            transformOrigin: 'center left',
                                            marginLeft: '2px',
                                        }}
                                        initial={{ scale: 1, opacity: 0 }}
                                        animate={
                                            isVisible
                                                ? {
                                                      scale: 1.2,
                                                      opacity: 1,
                                                      y: '-5%',
                                                  }
                                                : { scale: 1, opacity: 0 }
                                        }
                                        transition={{
                                            duration: 0.8,
                                            delay: 1.2,
                                        }}
                                    >
                                        +
                                    </motion.h1>
                                    <motion.h1
                                        style={{
                                            transformOrigin: 'center left',
                                            marginLeft: '2px',
                                        }}
                                        initial={{ scale: 1, opacity: 0 }}
                                        animate={
                                            isVisible
                                                ? {
                                                      scale: 1.2,
                                                      opacity: 1,
                                                      y: '-5%',
                                                  }
                                                : { scale: 1, opacity: 0 }
                                        }
                                        transition={{
                                            duration: 0.8,
                                            delay: 2.0,
                                        }}
                                    >
                                        +
                                    </motion.h1>
                                    <motion.h1
                                        style={{
                                            transformOrigin: 'center left',
                                            marginLeft: '2px',
                                        }}
                                        initial={{ scale: 1, opacity: 0 }}
                                        animate={
                                            isVisible
                                                ? {
                                                      scale: 1.2,
                                                      opacity: 1,
                                                      y: '-5%',
                                                  }
                                                : { scale: 1, opacity: 0 }
                                        }
                                        transition={{
                                            duration: 0.8,
                                            delay: 2.8,
                                        }}
                                    >
                                        +
                                    </motion.h1>
                                </div>
                            </div>
                            <p>{i18n.t('BannerBuyCredits.Content')}</p>
                        </div>
                    )}
                    <ButtonDefaultDark
                        width={'100%'}
                        height={56}
                        text={i18n.t('BannerBuyCredits.Button')}
                        to="/buycredits"
                    />
                </div>
                <div className={styles['banner-credits-right-container']}>
                    <img
                        src={
                            windowWidth > 450
                                ? BannerBuyCreditsMainPic
                                : BannerBuyCreditsMainPicMobile
                        }
                        alt="Amazon forest"
                    />
                </div>
            </div>
        </div>
    )
}

export default BannerBuyCredits
