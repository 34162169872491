import React, { useState, useEffect } from 'react'
import { i18n } from '../../lib/i18n/i18n'
import styles from './BannerReforestation.module.css'
import DevastatedPicture from '../../assets/images/BannerReforestationDevastedPic.png'
import ReforestatedPicture from '../../assets/images/BannerReforestationReforestatedPic.png'
import DevastedPictureMobile from '../../assets/images/BannerReforestationDevastedPic-mobile.png'
import ReforestatedPictureMobile from '../../assets/images/BannerReforestationReforestatedPic-mobile.png'
import InputReforestation from '../InputReforestation/InputReforestation'
import ButtonGuardian from '../../components/ButtonGuardian/ButtonGuardian'
import Slider from '../Slider/Slider'
import ToogleSwitch from '../ToggleSwitch/ToggleSwitch'
import useWindowWidth from '../../hooks/useWindowWidth'
import { motion, useAnimation } from 'framer-motion'

const BannerReforestation = () => {
    const [totalHectares, setTotalHectares] = useState(100)
    const [monthlyPayment, setMonthlyPayment] = useState(2300)
    const [monthlyPayment5, setMonthlyPayment5] = useState(8300)
    const [maxValue, setMaxValue] = useState(100)
    const [sliderValue, setSliderValue] = useState(50)
    const [errorMsg, setErrorMsg] = useState(false)
    const [debounceTimeout, setDebounceTimeout] = useState<any>(null)
    const [isToggleOn, setIsToggleOn] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [userInputChanged, setUserInputChanged] = useState(false)
    const controls = useAnimation()
    const windowWidth = useWindowWidth()
    const inputRef = React.useRef<HTMLInputElement>(null)

    // Animation for land pic when toggle is on
    useEffect(() => {
        const sequence = async () => {
            await controls.start({
                x: [0, -5, 5, -5, 5, -5, 5, -5, 5, -5],
                transition: { duration: 1 },
            })
        }

        sequence()
    }, [isToggleOn, controls])

    // waiting for toogle switch toogle
    const handleToggle = () => {
        setIsToggleOn(!isToggleOn)
    }

    // Slider inline style to change the background color
    const sliderStyle = {
        background: `linear-gradient(to right, #8FFF95 0%, #8FFF95 ${
            (sliderValue / (maxValue !== null ? maxValue : 100)) * 100
        }%, #FFFFFF ${
            (sliderValue / (maxValue !== null ? maxValue : 100)) * 100
        }%, #FFFFFF 100%)`,
    }

    // Slider lower limit is 4 (SAF)
    const handleSliderChange = (value: number) => {
        if (inputRef.current) {
            inputRef.current.blur()
        }

        if (value < 4) {
            setSliderValue(4)
        } else {
            setSliderValue(value)
            calculatePayments(value)

            if (debounceTimeout) {
                clearTimeout(debounceTimeout)
            }

            const newTimeout = setTimeout(() => {
                setIsToggleOn(true)
            }, 1000)
            setDebounceTimeout(newTimeout)
        }
    }

    // Target on the user input, apply rules and calculate the payments
    const handleTotalHectaresChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let value = e.target.value
        setUserInputChanged(true)
        if (value.length > 3) {
            value = value.slice(0, 3)
            e.target.value = value
        }

        if (debounceTimeout) {
            clearTimeout(debounceTimeout)
        }

        const newTimeout = setTimeout(() => {
            if (
                value.trim() === '' ||
                isNaN(parseInt(value)) ||
                parseInt(value) < 4 ||
                parseInt(value) > 200
            ) {
                setIsButtonDisabled(true)
                setErrorMsg(true)
                setTotalHectares(100)
                setMaxValue(100)
                setSliderValue(50)
                setMonthlyPayment(2300)
                setMonthlyPayment5(5950)
                setIsToggleOn(false)
                setUserInputChanged(false)
                return
            } else {
                setTotalHectares(parseInt(value))
                setMaxValue(parseInt(value))
                setSliderValue(Math.ceil(parseInt(value) / 2))
                setErrorMsg(false)
                calculatePayments(parseInt(value) / 2)
                setIsToggleOn(false)
            }
        }, 1000)

        setDebounceTimeout(newTimeout)
    }

    // If user clicks on the button without changing the input/ validate input, show error message
    const handleGuardianButtonClick = () => {
        if (userInputChanged === false) {
            setErrorMsg(true)
        }
    }

    // Round to tens to be apply on guardian earnings
    const roundToTens = (num: number): number => {
        return Math.round(num / 10) * 10
    }

    // Calculate the payments based on the devastated area and sanctu's parameters
    const calculatePayments = (devastatedArea: number) => {
        const realArea = devastatedArea - 4
        const paymentPerHectare = 50
        const payment = roundToTens(realArea * paymentPerHectare)
        const payment5 = roundToTens(realArea * paymentPerHectare + 6000)
        setMonthlyPayment(payment)
        setMonthlyPayment5(payment5)
        setErrorMsg(false)
        setIsButtonDisabled(false)
    }

    const currentDevastatedPicture =
        windowWidth < 450 ? DevastedPictureMobile : DevastatedPicture
    const currentReforestationPicture =
        windowWidth < 450 ? ReforestatedPictureMobile : ReforestatedPicture

    return (
        <div
            className={styles['bannerrefo-main-container']}
            id="reflorestation"
        >
            {windowWidth >= 450 && (
                <div className={styles['bannerrefo-main-container-text']}>
                    <h3>{i18n.t('BannerReforestation.Title')}</h3>
                    <h4>{i18n.t('BannerReforestation.SubTitle')}</h4>
                </div>
            )}
            <div className={styles['bannerrefor-container']}>
                <div className={styles['bannerrefor-land-animation']}>
                    <motion.img
                        src={
                            isToggleOn
                                ? currentReforestationPicture
                                : currentDevastatedPicture
                        }
                        alt={isToggleOn ? 'Reforested pic' : 'Desvated pic'}
                        animate={controls}
                    />
                    <div className={styles['bannerrefor-toggle-container']}>
                        <p>{i18n.t('BannerReforestation.Before')}</p>
                        <ToogleSwitch
                            isOn={isToggleOn}
                            handleToggle={handleToggle}
                        />
                        <p>{i18n.t('BannerReforestation.After')}</p>
                    </div>
                </div>
                <div className={styles['bannerrefor-input-form-container']}>
                    <div className={styles['bannerrefor-input-form']}>
                        {errorMsg ? (
                            <div className={styles['bannerrefor-error-msg']}>
                                <p>{i18n.t('BannerReforestation.ErrorMsg')}</p>
                            </div>
                        ) : null}
                        <InputReforestation
                            text={i18n.t('BannerReforestation.Question1')}
                            onChange={handleTotalHectaresChange}
                            placeholder={totalHectares.toString()}
                            ref={inputRef}
                        />
                        <Slider
                            maxValue={maxValue}
                            sliderValue={sliderValue}
                            onSliderChange={handleSliderChange}
                            style={sliderStyle}
                        />
                    </div>
                    <div className={styles['bannerrefor-earnings-header']}>
                        <h3>{i18n.t('BannerReforestation.EarningsHeader')}</h3>
                    </div>
                    <div className={styles['bannerrefor-totals-container']}>
                        <div className={styles['bannerrefor-area']}>
                            <p>{i18n.t('BannerReforestation.Now')}</p>
                            <div className={styles['bannerrefor-area-HAtag']}>
                                <h3>
                                    R${monthlyPayment.toLocaleString('en-US')}
                                </h3>
                            </div>
                        </div>
                        <div className={styles['bannerrefor-earnings']}>
                            <p>{i18n.t('BannerReforestation.FiveYears')}</p>
                            <div
                                className={styles['bannerrefor-earnings-month']}
                            >
                                <h3>
                                    R${monthlyPayment5.toLocaleString('en-US')}
                                </h3>
                            </div>
                        </div>
                    </div>
                    {windowWidth >= 450 && (
                        <div
                            className={
                                styles['bannerrefor-submit-button-desktop']
                            }
                        >
                            <ButtonGuardian
                                totalHectares={totalHectares}
                                reforestationArea={sliderValue}
                                onClick={handleGuardianButtonClick}
                                disabled={isButtonDisabled}
                                userInputChanged={userInputChanged}
                            />
                        </div>
                    )}
                </div>
            </div>
            {windowWidth < 450 && (
                <div className={styles['bannerrefor-submit-button-mobile']}>
                    <ButtonGuardian
                        totalHectares={totalHectares}
                        reforestationArea={sliderValue}
                        onClick={handleGuardianButtonClick}
                        disabled={isButtonDisabled}
                        userInputChanged={userInputChanged}
                    />
                </div>
            )}
        </div>
    )
}

export default BannerReforestation
