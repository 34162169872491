import { useState, useEffect } from 'react'

// Custom hook to get the current window height
const useWindowHeight = () => {
    // Initialize the state with the current window innerHeight
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)

    // Use the useEffect hook to run code when the component mounts or updates
    useEffect(() => {
        // Define the event handler for window resizing
        const handleResize = () => setWindowHeight(window.innerHeight)

        // Attach the event handler to the window's resize event
        window.addEventListener('resize', handleResize)

        // Cleanup function: remove the event handler when the component unmounts
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Empty dependency array means this effect runs once when the component mounts

    // Return the current window height
    return windowHeight
}

export default useWindowHeight
