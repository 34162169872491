import { i18n } from '../../lib/i18n/i18n'
import styles from './BannerOurServices.module.css'
import Badges from '../../components/Badge/Badge'
import ButtonNoBackground from '../../components/ButtonNoBackground/ButtonNoBackground'
import CardServices from './CardServices'
import card_services_1 from '../../assets/images/BannerOurServicesCard1.jpg'
import card_services_2 from '../../assets/images/BannerOurServicesCard2.jpg'
import card_services_3 from '../../assets/images/BannerOurServicesCard3.jpg'
import card_services_1_mobile from '../../assets/images/BannerOurServicesCard1-mobile.jpg'
import card_services_2_mobile from '../../assets/images/BannerOurServicesCard2-mobile.jpg'
import card_services_3_mobile from '../../assets/images/BannerOurServicesCard3-mobile.jpg'
import ArrowRight from '../../assets/images/BannerCarbonCreditScoreArrowRight.svg'
import ArrowLeft from '../../assets/images/BannerCarbonCreditScoreArrowLeft.svg'
import useHorizontalScroll from '../../hooks/useHorizontalScroll3Cards'
import useWindowHeight from '../../hooks/useWindowHeight'
import useWindowWidth from '../../hooks/useWindowWidth'

const BannerOurServices = () => {
    // Calling hook to handle horizontal scroll
    const {
        containerRef,
        handleScrollLeft,
        handleScrollRight,
        leftOpacity,
        rightOpacity,
    } = useHorizontalScroll()

    const windowHeight = useWindowHeight()
    const windowWidth = useWindowWidth()
    const currentLanguage = i18n.language
    const isPortugueseAndSmallScreen =
        currentLanguage === 'pt' && windowWidth < 450

    // Setting styles for header and paragraph when portuguese and small screen
    // Translations length are different, so we need to set different styles
    const headerStyle = isPortugueseAndSmallScreen
        ? {
              fontSize: '26px',
              lineHeight: '28px',
          }
        : {}

    const paragraphStyle = isPortugueseAndSmallScreen
        ? {
              fontSize: '18px',
              lineHeight: '20px',
          }
        : {}
    return (
        <div className={styles['ourservices-main-container']}>
            <div className={styles['ourservices-header-container']}>
                <Badges text={i18n.t('BannerOurServices.Badge')} />
                <div className={styles['ourservices-header-content']}>
                    <div className={styles['ourservices-header-text']}>
                        <h2 style={headerStyle}>
                            {i18n.t('BannerOurServices.Title')}
                        </h2>
                        <p style={paragraphStyle}>
                            {i18n.t('BannerOurServices.SubTitle')}
                        </p>
                    </div>
                    <div className={styles['ourservices-button']}>
                        <ButtonNoBackground
                            text={i18n.t('BannerOurServices.Button')}
                            to={'/contactus'}
                        />
                    </div>
                </div>
            </div>
            <div
                className={`${styles['ourservices-cards-grid']} ${
                    windowHeight < 630 && windowWidth < 450
                        ? styles['snap-start']
                        : ''
                }`}
                ref={containerRef}
            >
                <CardServices
                    image={
                        windowWidth > 450
                            ? card_services_1
                            : card_services_1_mobile
                    }
                    text={i18n.t('BannerOurServices.Card1Content')}
                    number={'01'}
                />
                <CardServices
                    image={
                        windowWidth > 450
                            ? card_services_2
                            : card_services_2_mobile
                    }
                    text={i18n.t('BannerOurServices.Card2Content')}
                    number={'02'}
                />
                <CardServices
                    image={
                        windowWidth > 450
                            ? card_services_3
                            : card_services_3_mobile
                    }
                    text={i18n.t('BannerOurServices.Card3Content')}
                    number={'03'}
                />
            </div>
            <div className={styles['ourservices-buttonscroll']}>
                <img
                    src={ArrowLeft}
                    alt="Seta para esquerda"
                    onClick={handleScrollLeft}
                    style={{ opacity: leftOpacity }}
                />
                <img
                    src={ArrowRight}
                    alt="Seta para direita"
                    onClick={handleScrollRight}
                    style={{ opacity: rightOpacity }}
                />
            </div>
        </div>
    )
}

export default BannerOurServices
