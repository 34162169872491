import React from 'react'
import styles from './SquareCardScore.module.css'
import { AnimatePresence, motion } from 'framer-motion'

// Component used on CardCarbonCreditScore

interface SquareCardScoreProps {
    backgroundColor: string
    delay: number
    animation: boolean
    scale: number
}

const SquareCardScore: React.FC<SquareCardScoreProps> = (props) => {
    return (
        <AnimatePresence initial={props.animation}>
            <motion.div
                initial={{ backgroundColor: 'var(--neutral-color)', scale: 1 }}
                animate={
                    props.animation
                        ? {
                              backgroundColor: props.backgroundColor,
                              scale: props.scale,
                          }
                        : { backgroundColor: 'var(--neutral-color)' }
                }
                transition={{ duration: 0.5, delay: props.delay }}
                className={styles['square-container']}
                // style={{ backgroundColor: props.backgroundColor }}
            ></motion.div>
        </AnimatePresence>
    )
}

export default SquareCardScore
