import { createContext, useContext, useState } from 'react'

const AnimationContext = createContext()

// Custom hook to access animation data from the context
export const useAnimation = () => {
    return useContext(AnimationContext)
}

export const AnimationProvider = ({ children }) => {
    // State to control whether the animation should run
    const [runAnimation, setRunAnimation] = useState(true)

    // Function to be called when the animation completes
    const onAnimationComplete = () => {
        setRunAnimation(false)
    }

    // Provide animation-related data to the context
    return (
        <AnimationContext.Provider
            value={{ runAnimation, onAnimationComplete }}
        >
            {children}
        </AnimationContext.Provider>
    )
}
