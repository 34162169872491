import styles from './ButtonDefaultDark.module.css'
import arrow_right from '../../assets/images/ButtonDarkDefault.svg'

// Component used on BannerBuyCredits

interface ButtonDefaultDarkProps {
    width?: string | number
    height?: string | number
    text: string
    type?: 'button' | 'submit' | 'reset' | undefined
    to?: string | null
}

const ButtonDefaultDark: React.FC<ButtonDefaultDarkProps> = ({
    width,
    height,
    text,
    type,
    to,
}) => {
    const handleClick = () => {
        if (to) {
            window.location.href = to
        }
    }

    if (!text) {
        return null
    }

    return (
        <button
            type={type}
            className={styles['button-default-dark']}
            style={{
                width: width,
                height: height,
            }}
            onClick={handleClick}
        >
            <p className={styles['button-default-dark-p']}>{text}</p>
            <img
                src={arrow_right}
                alt="Sanctu logo"
                style={{
                    height: '24px',
                    width: '24px',
                }}
            />
        </button>
    )
}

export default ButtonDefaultDark
