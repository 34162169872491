import React, { useEffect, useRef, useState } from 'react'
import styles from './Nav.module.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { i18n } from '../../lib/i18n/i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import Sanctu_logo from '../../assets/images/NavbarSanctuLogo.svg'
import Sanctu_text from '../../assets/images/NavbarSanctuText.svg'
import Language_check from '../../assets/images/NavbarLanguageCheck.svg'
import Menu from '../../assets/images/NavbarDropDownMenuIcon.svg'
import Close_menu from '../../assets/images/NavbarDropDownClose.svg'
import { useLocation } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { useAnimation } from '../../hooks/animationContext'

// Define constant arrays for menu links and language options for easy management and reusability.
const menuLinks = [
    { to: '/guardians', label: i18n.t('Nav.Guardian') },
    { to: '/buycredits', label: i18n.t('Nav.BuyCredits') },
    { to: '/joinus', label: i18n.t('Nav.JoinUs') },
    { to: '/contactus', label: i18n.t('Nav.ContactUs') },
]

const languages = [
    { code: 'pt', label: 'PT' },
    { code: 'en', label: 'EN' },
]

const menuLinksMobile = [
    { to: '/guardians', label: i18n.t('Nav.Guardian') },
    { to: '/buycredits', label: i18n.t('Nav.BuyCredits') },
    { to: '/joinus', label: i18n.t('Nav.JoinUs') },
    { to: '/contactus', label: i18n.t('Nav.ContactUs') },
]

const Nav: React.FC = () => {
    // State for handling the visibility of the mobile menu
    const [showMobileMenu, setShowMobileMenu] = React.useState(false)
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    const { t, i18n } = useTranslation()

    // State for handling the visibility of the language dropdown
    const [languageDropdownVisible, setLanguageDropdownVisible] =
        useState(false)

    const { runAnimation, onAnimationComplete } = useAnimation()

    // Refs for the language and about dropdowns, used to detect clicks outside of the dropdowns
    const languageDropdownRef = useRef<HTMLDivElement>(null)

    const location = useLocation()

    // Animation config for the main menu and logo
    const animationConfig = {
        animate: runAnimation &&
            location.pathname === '/' && { x: 0, opacity: 1 },
        transition: { duration: 1 },
        onAnimationComplete: onAnimationComplete,
    }

    // Animation config for the language and about dropdowns
    const animationConfigDropdown = {
        initial: {
            opacity: 0,
        },
        transition: { duration: 0.8 },
        exit: {
            opacity: 0,
        },
    }

    // Animation config for the mobile menu
    const AnimationConfigMobileMenu = {
        initial: {
            opacity: 0,
            y: '-100vw',
            height: '1%',
            width: 'calc(100% - 32px)',
        },
        animate: {
            opacity: 1,
            y: 0,
            height: 'calc(100vh - 166px)',
        },
        exit: {
            opacity: 0,
            y: '-100%',
        },
        transition: { duration: 1 },
    }

    const renderMenuLinks = () =>
        menuLinks.map(({ to, label }) => (
            <div
                className={`${styles['menu-links']} ${
                    location.pathname === to ? styles['active-link'] : ''
                }`}
                key={to}
            >
                <Link to={to}> {t(label)} </Link>
            </div>
        ))

    // Function to render the language links, if language is different from the current language, change the language and reload the page to update translations
    const renderLanguageLinks = () =>
        languages.map(({ code, label }) => (
            <div
                className={styles['language-links']}
                onClick={(e) => {
                    e.preventDefault()
                    if (i18n.language !== code) {
                        i18n.changeLanguage(code)
                        const currentUrl = window.location.href
                        window.location.href = currentUrl
                    }
                }}
                key={code}
            >
                {label}
                {i18n.language.includes(code) ? (
                    <div className={styles['language-checkmark']}>
                        <img src={Language_check} alt="check" />
                    </div>
                ) : (
                    <div className={styles['language-no-checkmark']}></div>
                )}
            </div>
        ))

    const handleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu)
    }

    // Add a class to the body to hide the scrollbar when the mobile menu is open
    useEffect(() => {
        if (showMobileMenu) {
            document.body.classList.add('hide-scroll-bar')
        } else {
            document.body.classList.remove('hide-scroll-bar')
        }
    }, [showMobileMenu])

    // Custom hook to detect clicks outside of the dropdowns
    function useClickOutsideHandler(ref: any, setVisible: any) {
        useEffect(() => {
            function handleClickOutside(event: MouseEvent) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setVisible(false)
                }
            }

            document.addEventListener('mousedown', handleClickOutside)

            return () => {
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }, [ref, setVisible])
    }

    // Usage for the language dropdown
    useClickOutsideHandler(languageDropdownRef, setLanguageDropdownVisible)

    const onTouchStart = (e: any) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientY)
    }
    const onTouchMove = (e: any) => {
        setTouchEnd(e.targetTouches[0].clientY)
    }

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        if (distance > 20) {
            setShowMobileMenu(false)
        }
        setTouchStart(null)
        setTouchEnd(null)
    }

    return (
        <div
            className={styles['navbar-main-container']}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            style={{ touchAction: 'none' }}
        >
            <div className={styles['navbar-container']} id="navbar">
                <motion.div
                    initial={
                        runAnimation &&
                        location.pathname === '/' && { x: '-200%', opacity: 0 }
                    }
                    {...animationConfig}
                >
                    <Link to="/">
                        <img src={Sanctu_logo} alt="Logo" />
                        <img
                            src={Sanctu_text}
                            alt="Sanctu text logo"
                            className={styles['navbar-logo-text']}
                        />
                    </Link>
                </motion.div>
                {/* Main menu with links for Desktop */}
                <motion.div
                    className={styles['links-container']}
                    initial={
                        runAnimation &&
                        location.pathname === '/' && { x: '+100%', opacity: 0 }
                    }
                    {...animationConfig}
                >
                    <div className={styles['links-container-navigation']}>
                        {renderMenuLinks()}
                    </div>
                    <div className={styles['links-container-language']}>
                        <div
                            className={styles['language-dropdown']}
                            ref={languageDropdownRef}
                        >
                            <button
                                className={styles['dropdown-button']}
                                onClick={() =>
                                    setLanguageDropdownVisible(
                                        !languageDropdownVisible
                                    )
                                }
                            >
                                <p>{t('Nav.EN')}</p>
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    style={{
                                        color: 'var(--pine-color)',
                                        transform: languageDropdownVisible
                                            ? 'rotate(180deg)'
                                            : 'rotate(0)',
                                        transition:
                                            'transform 0.5s ease-in-out',
                                    }}
                                />
                            </button>
                            <AnimatePresence>
                                {languageDropdownVisible ? (
                                    <motion.div
                                        className={
                                            styles['language-dropdown-content']
                                        }
                                        initial={
                                            animationConfigDropdown.initial
                                        }
                                        animate={{
                                            opacity: 1,
                                            visibility: 'visible',
                                        }}
                                        transition={
                                            animationConfigDropdown.transition
                                        }
                                        exit={animationConfigDropdown.exit}
                                    >
                                        {renderLanguageLinks()}
                                    </motion.div>
                                ) : (
                                    ''
                                )}
                            </AnimatePresence>
                        </div>
                    </div>
                </motion.div>

                {/* Menu Dropdown with links for Mobile */}
                <div
                    className={styles['mobile-button-container']}
                    onClick={handleMobileMenu}
                >
                    {/* If mobile menu is open, show close icon, else show menu icon */}
                    {showMobileMenu ? (
                        <button className={styles['mobile-dropdown-button']}>
                            <img src={Close_menu} alt="Close icon" />
                        </button>
                    ) : (
                        <motion.button
                            className={styles['mobile-dropdown-button']}
                            initial={
                                runAnimation &&
                                location.pathname === '/' && {
                                    x: '+100%',
                                    opacity: 0,
                                }
                            }
                            {...animationConfig}
                        >
                            <img src={Menu} alt="Menu icon" />
                        </motion.button>
                    )}
                </div>
            </div>

            {/* If mobile menu is open, show menu content, else hide it */}
            <AnimatePresence>
                {showMobileMenu ? (
                    <motion.div
                        className={styles['mobile-dropdown-content']}
                        {...AnimationConfigMobileMenu}
                    >
                        <div className={styles['mobile-dropdown-links']}>
                            {menuLinksMobile.map(({ to, label }) => (
                                <Link
                                    className={`${
                                        location.pathname === to
                                            ? styles['active-mobile-link']
                                            : ''
                                    }`}
                                    to={to}
                                    key={to}
                                >
                                    {t(label)}
                                </Link>
                            ))}
                        </div>
                        <div
                            className={
                                styles['mobile-language-links-container']
                            }
                        >
                            <p>{t('Nav.Language')}</p>
                            <div className={styles['mobile-language-links']}>
                                {renderLanguageLinks()}
                            </div>
                        </div>
                    </motion.div>
                ) : (
                    ''
                )}
            </AnimatePresence>
        </div>
    )
}

export default Nav
