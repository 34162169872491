import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import styles from './Badge.module.css'

interface BadgeProps {
    text: string
    backgroundColor?: string
}

const Badge: React.FC<BadgeProps> = ({ text, backgroundColor }) => {
    const ref = useRef(null)
    const [isInView, setIsInView] = useState(false)

    // Observer to check if the element is in view
    // Trigger to start animation is when the element is 50% in view
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.intersectionRatio > 0.5) {
                    setIsInView(true)
                    observer.disconnect()
                }
            },
            {
                rootMargin: '0px 0px -50% 0px',
                threshold: 1,
            }
        )

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [ref])

    // Animation for the badge
    const badgeAnimation = {
        initial: { scale: 0 },
        animate: isInView ? { scale: 1 } : {},
        transition: { duration: 0.8, ease: 'easeOut' },
    }

    return (
        <motion.div
            className={styles['badge-main-container']}
            style={{ backgroundColor: backgroundColor }}
            ref={ref}
            initial={badgeAnimation.initial}
            animate={badgeAnimation.animate}
            transition={badgeAnimation.transition}
        >
            {text}
        </motion.div>
    )
}

export default Badge
