import { i18n } from '../../lib/i18n/i18n'
import { motion } from 'framer-motion'
import styles from './BannerWhats.module.css'
import ButtonWhatsApp from '../ButtonWhatsApp/ButtonWhatsApp'
import { useEffect, useRef, useState } from 'react'
import useWindowWidth from '../../hooks/useWindowWidth'

const BannerWhats = () => {
    const [animationComplete, setAnimationComplete] = useState(false)
    const containerRef = useRef(null)
    const windowWidth = useWindowWidth()
    const isMobile = windowWidth < 768

    // Detect when the banner is visible on the screen and animate it
    useEffect(() => {
        const currentContainer = containerRef.current

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setAnimationComplete(true)
                }
            },
            { threshold: 1 }
        )

        if (currentContainer) {
            observer.observe(currentContainer)
        }

        return () => {
            if (currentContainer) {
                observer.unobserve(currentContainer)
            }
        }
    }, [])

    return (
        <div ref={containerRef} className={styles['banner-whats-container']}>
            <div className={styles['banner-whats-content']}>
                <div className={styles['banner-whats-text']}>
                    <h2>{i18n.t('BannerWhats.Title')}</h2>

                    <motion.p
                        animate={{
                            fontSize: animationComplete
                                ? isMobile
                                    ? '24px'
                                    : '32px'
                                : isMobile
                                ? '20px'
                                : '24px',
                        }}
                        transition={{
                            duration: 0.6,
                            delay: animationComplete ? 0.6 : 0,
                        }}
                    >
                        {i18n.t('BannerWhats.Content')}
                    </motion.p>
                </div>

                <motion.div
                    animate={{
                        scale: animationComplete ? 1.08 : 1,
                    }}
                    transition={{
                        duration: 0.6,
                        delay: 1.5,
                    }}
                >
                    <ButtonWhatsApp text={i18n.t('BannerWhats.Button')} />
                </motion.div>
            </div>
        </div>
    )
}

export default BannerWhats
