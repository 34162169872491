// Import external library
import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSquareInstagram,
    faLinkedin,
    faSquareFacebook,
    faSquareXTwitter,
    faSquareWhatsapp,
} from '@fortawesome/free-brands-svg-icons'

// Import local files
import styles from './Footer.module.css'
import { i18n } from '../../lib/i18n/i18n'
import letters from '../../assets/images/FooterSanctuLetters.svg'
import logo from '../../assets/images/FooterSanctuLogo.svg'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import useWindowWidth from '../../hooks/useWindowWidth'

interface LinkItem {
    text: string
    url: string
}

interface IconLink {
    icon: React.ReactNode
    url: string
}

// Component to render a list of icons
const IconsListHorizontal: React.FC<{ links: IconLink[] }> = ({ links }) => (
    <div className={styles['icons-horizontal']}>
        {links.map((link, index) =>
            link.url === '#' ? (
                <Link
                    to={link.url}
                    key={index}
                    className={styles['footer-icons']}
                >
                    {link.icon}
                </Link>
            ) : (
                <Link
                    to={link.url}
                    key={index}
                    target="_blank"
                    className={styles['footer-icons']}
                >
                    {link.icon}
                </Link>
            )
        )}
    </div>
)

// Component to render the footer
const Footer: React.FC = () => {
    // Links to be rendered on the footer divided by side
    const leftLinks = [
        { text: i18n.t('Footer.Guardians'), url: '/guardians' },
        { text: i18n.t('Footer.BuyCredits'), url: '/buycredits' },
        { text: i18n.t('Footer.JoinUs'), url: '/joinus' },
    ]

    const rightLinks = [
        { text: i18n.t('Footer.Contact'), url: '/contactus' },
        { text: i18n.t('Footer.CookieManager'), url: '/cookies' },
    ]

    // const bottomLinks = [
    //     { text: i18n.t('Footer.Terms&Conditions'), url: '/' },
    //     { text: i18n.t('Footer.PrivacyPolicy'), url: '/' },
    // ]

    // Icons to be rendered on the footer
    const icons = [
        {
            icon: <FontAwesomeIcon icon={faSquareFacebook} size="lg" />,
            url: '#',
        },
        {
            icon: <FontAwesomeIcon icon={faSquareInstagram} size="lg" />,
            url: '#',
        },
        {
            icon: <FontAwesomeIcon icon={faSquareXTwitter} size="lg" />,
            url: '#',
        },
        {
            icon: <FontAwesomeIcon icon={faLinkedin} size="lg" />,
            url: 'https://www.linkedin.com/company/sanctu/',
        },
        {
            icon: <FontAwesomeIcon icon={faSquareWhatsapp} size="lg" />,
            url: '#',
        },
    ]

    // Component to render a list of links
    const LinkListVertical: React.FC<{ links: LinkItem[] }> = ({ links }) => (
        <div
            className={
                windowWidth > 480
                    ? styles['links-vertical']
                    : styles['links-vertical-mobile']
            }
        >
            {links.map((link, index) => (
                <Link to={link.url} key={index}>
                    {link.text}
                </Link>
            ))}
        </div>
    )

    // const LinkListHorizontal: React.FC<{ links: LinkItem[] }> = ({ links }) => (
    //     <div
    //         className={
    //             windowWidth > 480
    //                 ? styles['links-horizontal']
    //                 : styles['links-horizontal-mobile']
    //         }
    //     >
    //         {links.map((link, index) => (
    //             <Link to={link.url} key={index}>
    //                 {link.text}
    //             </Link>
    //         ))}
    //     </div>
    // )

    const [isVisible, setIsVisible] = useState(false)
    const windowWidth = useWindowWidth()
    const logoRef = useRef<HTMLDivElement>(null)

    // Scroll to the top of the page with a smooth animation
    const scrollToTop = () => {
        const navElement = document.getElementById('navbar')
        navElement?.scrollIntoView({ behavior: 'smooth' })
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        )
    }

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '-20%',
            threshold: 0.6,
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true)
                    observer.disconnect()
                }
            })
        }, options)

        if (logoRef.current) {
            observer.observe(logoRef.current)
        }

        return () => {
            observer.disconnect()
        }
    }, [])

    return (
        <div className={styles['footer-main-container']}>
            <footer className={styles['footer-container']}>
                <div className={styles['footer-container-upper']}>
                    <motion.div
                        className={styles['footer-container-logo']}
                        initial={{ scale: 0.8 }}
                        animate={isVisible ? { scale: 1 } : { scale: 0.96 }}
                        transition={{ duration: 0.8, delay: 0.5 }}
                    >
                        <motion.div
                            whileHover={
                                windowWidth > 480
                                    ? {
                                          scale: 1.05,
                                          transition: { duration: 0.3 },
                                      }
                                    : {}
                            }
                            onClick={scrollToTop}
                        >
                            <img
                                src={logo}
                                alt="Logo"
                                className={styles['footer-logo']}
                            />
                            <img src={letters} alt="letters" />
                        </motion.div>
                    </motion.div>
                    <div className={styles['footer-container-links']}>
                        <div
                            className={styles['footer-container-links-left']}
                            ref={logoRef}
                        >
                            <LinkListVertical links={leftLinks} />
                            {/* Mobile version of the links, display none on desktop/tablet size */}
                            <div
                                className={
                                    styles['footer-container-links-mobile']
                                }
                            >
                                <LinkListVertical links={rightLinks} />
                            </div>
                            <IconsListHorizontal links={icons} />
                        </div>
                        {/* Desktop version of the right links, display none on mobile size */}
                        <div className={styles['footer-container-links-right']}>
                            <LinkListVertical links={rightLinks} />
                        </div>
                    </div>
                </div>
                <div className={styles['footer-container-bottom']}>
                    <span className={styles['footer-container-bottom-left']}>
                        © 2024 Sanctu
                    </span>
                    {/* <LinkListHorizontal links={bottomLinks} /> */}
                </div>
            </footer>
        </div>
    )
}

export default Footer
