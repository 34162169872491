import BannerAnsweredQuestions from '../../components/BannerAnsweredQuestions/BannerAnsweredQuestions'
import BannerGuardians from '../../components/BannerGuardians/BannerGuardians'
import BannerOurServices from '../../components/BannerOurServices/BannerOurServices'
import BannerReforestation from '../../components/BannerReforestation/BannerReforestation'
import BannerVideo from '../../components/BannerVideo/BannerVideo'
import BannerWhats from '../../components/BannerWhats/BannerWhats'
import Footer from '../../components/Footer/Footer'
import Nav from '../../components/Nav/Nav'
import { i18n } from '../../lib/i18n/i18n'
import styles from './Guardians.module.css'
import useWindowHeight from '../../hooks/useWindowHeight'
import useWindowWidth from '../../hooks/useWindowWidth'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useEffect, useState } from 'react'

const Guardians = () => {
    const questions = [
        {
            question: i18n.t('BannerAnsweredQuestions.Question1'),
            answer: i18n.t('BannerAnsweredQuestions.Answer1'),
        },
        {
            question: i18n.t('BannerAnsweredQuestions.Question2'),
            answer: i18n.t('BannerAnsweredQuestions.Answer2'),
        },
        {
            question: i18n.t('BannerAnsweredQuestions.Question3'),
            answer: i18n.t('BannerAnsweredQuestions.Answer3'),
        },
        {
            question: i18n.t('BannerAnsweredQuestions.Question4'),
            answer: i18n.t('BannerAnsweredQuestions.Answer4'),
        },
    ]

    const windowHeight = useWindowHeight()
    const windowWidth = useWindowWidth()

    const [android, setAndroid] = useState(false)
    useEffect(() => {
        const isAndroid = /Android/i.test(navigator.userAgent)
        if (isAndroid) {
            setAndroid(true)
        } else {
            setAndroid(false)
        }
    }, [])

    return (
        <HelmetProvider>
            <div>
                <Helmet>
                    <title>{i18n.t('Metadata.GuardiansTitle')}</title>
                    <meta
                        name="description"
                        content={i18n.t('Metadata.GuardiansDescription')}
                    />
                </Helmet>
                <div
                    className={
                        android
                            ? styles['snap-control-css-android']
                            : styles['snap-control-css']
                    }
                >
                    <div className={styles['snap-section-navbanner']}>
                        <Nav />
                        <BannerGuardians />
                    </div>
                    <div className={styles['container']}>
                        <div
                            className={`${
                                styles['snap-section-reforestation']
                            } ${
                                windowHeight < 630 && windowWidth < 450
                                    ? styles['snap-start']
                                    : ''
                            }`}
                        >
                            <BannerReforestation />
                        </div>
                        <div className={styles['snap-section-video']}>
                            <BannerVideo />
                        </div>
                        <div className={styles['snap-section-ourservices']}>
                            <BannerOurServices />
                        </div>
                        <div className={styles['snap-section-whats']}>
                            <BannerWhats />
                        </div>
                        <div className={styles['snap-section-faq']}>
                            <BannerAnsweredQuestions
                                badgeText={i18n.t(
                                    'BannerAnsweredQuestions.BadgeText'
                                )}
                                badgeBackgroundColor="var(--white-color)"
                                bannerTitle={i18n.t(
                                    'BannerAnsweredQuestions.BannerTitle'
                                )}
                                questions={questions}
                                bannerBackgroundColor="var(--neutral-color)"
                            />
                        </div>
                    </div>
                    <div className={styles['snap-section-foooter']}>
                        <Footer />
                    </div>
                </div>
            </div>
        </HelmetProvider>
    )
}

export default Guardians
