import { useState, useEffect } from 'react'

const useButtonWidth = () => {
    const [buttonWidth, setButtonWidth] = useState(625)

    useEffect(() => {
        const handleResize = () => {
            const currentWidth = window.innerWidth
            if (currentWidth >= 900 && currentWidth <= 1200) {
                setButtonWidth(450)
            } else {
                setButtonWidth(625)
            }
        }

        handleResize()

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return buttonWidth
}

export default useButtonWidth
