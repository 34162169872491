// Constants for fields validations using regex
const VALIDATION_REGEX = {
    USERNAME: /^[a-zA-Z0-9\s]{3,23}$/,
    EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    CPF: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
    PHONE_NUMBER: /^\(\d{2}\) \d{4,5}-\d{4}$/,
    CAR: /^[A-Z]{2}-\d{7}-[A-Z\d]{4}\.[A-Z\d]{4}\.[A-Z\d]{4}\.[A-Z\d]{4}\.[A-Z\d]{4}\.[A-Z\d]{4}\.[A-Z\d]{4}\.[A-Z\d]{4}$/,
}

// Validate a field based on its name and value, if it's valid, return true, otherwise, return false
const validateField = (fieldName: string, value: string): boolean => {
    switch (fieldName) {
        case 'first_name':
            return VALIDATION_REGEX.USERNAME.test(value)
        case 'last_name':
            return VALIDATION_REGEX.USERNAME.test(value)
        case 'email':
            return VALIDATION_REGEX.EMAIL.test(value)
        case 'cpf':
            return VALIDATION_REGEX.CPF.test(value)
        case 'phone_number':
            return value.length > 7
        case 'car':
            return VALIDATION_REGEX.CAR.test(value)
        case 'subject':
            return value.length > 0
        case 'message_content':
            return value.length > 0
        default:
            return true
    }
}

export default validateField
