import React from 'react'
import styles from './CardOurPeople.module.css'
import { motion, MotionProps } from 'framer-motion'

// Component used on BannerOurPeople

type CardOurPeopleProps = {
    title: string
    icon: string
    style?: React.CSSProperties
    animationProps?: MotionProps
}

const CardOurPeople: React.FC<CardOurPeopleProps> = ({
    title,
    icon,
    style,
    animationProps,
}) => {
    return (
        <div style={style} className={styles['cardourpeople-main-container']}>
            <div className={styles['cardourpeople-icon']}>
                <img src={icon} alt="Our people Icon" />
            </div>
            <div className={styles['cardourpeople-text-content']}>
                <motion.h4
                    style={{ transformOrigin: 'center left' }}
                    {...animationProps}
                >
                    {title}
                </motion.h4>
            </div>
        </div>
    )
}

export default CardOurPeople
