import styles from './CardFaq.module.css'
import React, { useState } from 'react'

// Component used on BannerFaqs

interface CardFaqProps {
    question: string
    answer: string
    style?: React.CSSProperties // Optional style if the card is the first or last - Apply border radius
}

const CardFaq: React.FC<CardFaqProps> = ({ question, answer, style }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <div className={styles['cardfaq-main-container']} style={style}>
            <div
                className={styles['cardfaq-question-container']}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <p>{question}</p>
                <span>{isExpanded ? '-' : '+'}</span>
            </div>
            {isExpanded && (
                <div className={styles['cardfaq-answer-container']}>
                    <p>{answer}</p>
                </div>
            )}
        </div>
    )
}

export default CardFaq
